import parseLogicalExpression from 'modules/common/utils/queryParser';
import * as yup from 'yup';
import { FIELD } from '../constants';
/**
 * Query box form validations define here
 */

const queryBoxValidation = yup.object().shape({
  setA: yup
    .string()
    .nullable()
    .test(
      'combine-operator-validation',
      'Invalid combine operator. Please use uppercase AND or OR.',
      (value) => {
        if (value) {
          try {
            const parsedArray = parseLogicalExpression(value);
            return parsedArray
              .filter((_, index) => index % 2 !== 0)
              .every((item) => ['AND', 'OR'].includes(item));
          } catch (error) {
            return false;
          }
        }
        return true;
      }
    )
    .test('label-validation', 'Invalid query format', (value) => {
      if (value) {
        try {
          const parsedArray = parseLogicalExpression(value);
          return parsedArray
            .filter((_, index) => index % 2 === 0)
            .every((item) => {
              if (
                Array.isArray(item) &&
                item.length === 3 &&
                item.every((subItem) => typeof subItem === 'string')
              ) {
                const [field, , queryValue] = item;
                return [FIELD].includes(field) && queryValue;
              }
              return false;
            });
        } catch (error) {
          return false;
        }
      }
      return true;
    })
    .test(
      'operator-validation',
      'Invalid operator. Please use uppercase EQUAL, NOT EQUAL or LIKE.',
      (value) => {
        if (value) {
          try {
            const parsedArray = parseLogicalExpression(value);
            return parsedArray
              .filter((_, index) => index % 2 === 0)
              .every((item) => {
                if (
                  Array.isArray(item) &&
                  item.length === 3 &&
                  item.every((subItem) => typeof subItem === 'string')
                ) {
                  const [, operator] = item;
                  return ['EQUAL', 'NOT EQUAL', 'LIKE'].includes(operator);
                }
                return false;
              });
          } catch (error) {
            return false;
          }
        }
        return true;
      }
    ),
  setB: yup
    .string()
    .nullable()
    .test(
      'combine-operator-validation',
      'Invalid combine operator. Please use uppercase AND or OR.',
      (value) => {
        if (value) {
          try {
            const parsedArray = parseLogicalExpression(value);
            return parsedArray
              .filter((_, index) => index % 2 !== 0)
              .every((item) => ['AND', 'OR'].includes(item));
          } catch (error) {
            return false;
          }
        }
        return true;
      }
    )
    .test('label-validation', 'Invalid query format', (value) => {
      if (value) {
        try {
          const parsedArray = parseLogicalExpression(value);
          return parsedArray
            .filter((_, index) => index % 2 === 0)
            .every((item) => {
              if (
                Array.isArray(item) &&
                item.length === 3 &&
                item.every((subItem) => typeof subItem === 'string')
              ) {
                const [field, , queryValue] = item;
                return ['breakdown'].includes(field) && queryValue;
              }
              return false;
            });
        } catch (error) {
          return false;
        }
      }
      return true;
    })
    .test(
      'operator-validation',
      'Invalid operator. Please use uppercase EQUAL, NOT EQUAL or LIKE.',
      (value) => {
        if (value) {
          try {
            const parsedArray = parseLogicalExpression(value);
            return parsedArray
              .filter((_, index) => index % 2 === 0)
              .every((item) => {
                if (
                  Array.isArray(item) &&
                  item.length === 3 &&
                  item.every((subItem) => typeof subItem === 'string')
                ) {
                  const [, operator] = item;
                  return ['EQUAL', 'NOT EQUAL', 'LIKE'].includes(operator);
                }
                return false;
              });
          } catch (error) {
            return false;
          }
        }
        return true;
      }
    ),
});
//
export default queryBoxValidation;
