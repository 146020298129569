import { createSlice } from '@reduxjs/toolkit';
import { signInActions } from 'modules/sign-in/slice';
import { startOfMonth, subMonths, endOfMonth } from 'date-fns';
import { dashboardActions } from 'modules/dashboard/slice';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import { ALIGNED, BOOKING_DATE, REPORT_TYPE, SLICE, TAGS } from 'modules/dashboard/constants';
import { getHotelFocusOnValue } from 'modules/dashboard/functions';
import { authActions } from 'modules/common/auth/slice';
import ERROR_MESSAGE_TYPES from 'modules/common/constants/error-messages';
import { isEmpty } from 'modules/common/helpers/object';
/**
 * Initial states of Trend Time widget functions are defined here
 */
const trendStayDate = {
  startDate: new Date(startOfMonth(subMonths(new Date(), 1))).toISOString(),
  endDate: new Date(endOfMonth(subMonths(new Date(), 1))).toISOString(),
  key: 'selection',
};
//
const bookingStartDate = new Date(BOOKING_DATE).toISOString();
//
export const initialState = {
  loading: false,
  trendTimeAlignment: null,
  trendTimeDateComparison: null,
  trendTimeFilterList: [],
  trendTimeTabularFilterList: [],
  trendTimeAlignmentToggle: null,
  trendTimeCustomDate: 'start',
  trendTimeWeeklyView: false,
  isTrendTimeWeeklyViewDisabled: false,
  trendTimeStartDate: trendStayDate.startDate,
  trendTimeEndDate: trendStayDate.endDate,
  trendTimeDateSelected: trendStayDate.startDate,
  trendTimeUrl: '',
  isTrendDeAggregate: false,
  isTrendDeAggregateDisabled: false,
  trendSymmetricComparisonToggle: true,
  totalRevenueIsTableView: false,
  totalOccupiedRoomIsTableView: false,
  averageDailyRateIsTableView: false,
  averageBookingWindowIsTableView: false,
  averageLengthOfStayIsTableView: false,
  numberOfCancellationIsTableView: false,
  reservationCountIsTableView: false,
  isCumulative: false,
  revenueIsDisplayTT: true,
  occupiedRoomsIsDisplayTT: true,
  adrIsDisplayTT: true,
  avgBookingWindowIsDisplayTT: false,
  avgLosIsDisplayTT: false,
  cxlIsDisplayTT: false,
  symmetricComparisonIsDisplay: false,
  trendHotelFocusOn: {},
  enableSecondaryHotelGroups: false,
  isTrendHotelAggregate: false,
  trendHotelNormalizedView: true,
  trendHotelNormalizedFilterList: [],
  trendHotelFilterList: [],
  trendHotelTabularFilterList: [],
  revenueIsDisplayTH: true,
  occupiedRoomsIsDisplayTH: true,
  adrIsDisplayTH: true,
  avgBookingWindowIsDisplayTH: false,
  avgLosIsDisplayTH: false,
  cxlIsDisplayTH: false,
  trendSegmentFocusOn: [],
  segmentGroup1: [],
  segmentGroup2: [],
  trendSegmentChartBreakdown: null,
  isSegmentDetailed: false,
  showCustomGroupingFilterName: false,
  activeTrendTab: REPORT_TYPE.TREND_TIME,
  trendSegmentFilterList: [],
  trendSegmentTabularFilterList: [],
  revenueIsDisplayTS: true,
  occupiedRoomsIsDisplayTS: true,
  adrIsDisplayTS: true,
  avgBookingWindowIsDisplayTS: false,
  avgLosIsDisplayTS: false,
  cxlIsDisplayTS: false,
  trendTimeVisuals: [],
  trendTimeTabularVisuals: [],
  trendTimeWeeklyVisuals: [],
  trendTimeWeeklyTabularVisuals: [],
  trendTimeCumulativeTabularVisuals: [],
  trendTimeCumulativeVisuals: [],
  trendTimeSegmentVisuals: [],
  trendTimeSegmentTabularVisuals: [],
  trendTimeSegmentWeeklyVisuals: [],
  trendTimeSegmentWeeklyTabularVisuals: [],
  trendTimeSegmentCumulativeTabularVisuals: [],
  trendTimeSegmentCumulativeVisuals: [],
  trendTimePercentageVisuals: [],
  trendTimePercentageTabularVisuals: [],
  trendTimeMovingAverageVisuals: [],
  trendTimeMovingAverageTabularVisuals: [],
  trendSegmentVisuals: [],
  trendSegmentTabularVisuals: [],
  trendSegmentWeeklyVisuals: [],
  trendSegmentWeeklyTabularVisuals: [],
  trendSegmentCumulativeTabularVisuals: [],
  trendSegmentCumulativeVisuals: [],
  trendSegmentPercentageVisuals: [],
  trendSegmentPercentageTabularVisuals: [],
  trendSegmentMovingAverageVisuals: [],
  trendSegmentMovingAverageTabularVisuals: [],
  trendHotelsVisual: [],
  trendHotelsTabularVisual: [],
  trendHotelsWeeklyVisual: [],
  trendHotelWeeklyTabularVisuals: [],
  trendHotelCumulativeTabularVisuals: [],
  trendHotelCumulativeVisuals: [],
  trendHotelPercentageVisuals: [],
  trendHotelPercentageTabularVisuals: [],
  trendHotelMovingAverageVisuals: [],
  trendHotelMovingAverageTabularVisuals: [],
  tabularView: false,
  isTrendForecast: false,
  isForecastDisabled: false,
  segmentSetAName: '',
  segmentSetBName: '',
  segmentSetAQuery: '',
  segmentSetBQuery: '',
  selectedFilterName: '',
  isPrimary: false,
  isTrendPercentageChange: false,
  isPercentageChangeDisabled: false,
  //
  trendHotelForecastOffVisual: [],
  trendHotelForecastOffTabularVisual: [],
  trendHotelForecastOffWeeklyVisual: [],
  trendHotelForecastOffWeeklyTabularVisuals: [],
  trendHotelForecastOffCumulativeTabularVisuals: [],
  trendHotelForecastOffCumulativeVisuals: [],
  trendHotelForecastOffPercentageVisuals: [],
  trendHotelForecastOffPercentageTabularVisuals: [],
  //
  adjustedStartDate: new Date(bookingStartDate).toISOString(),
  adjustedEndDate: new Date().toISOString(),
  granularity: null,
  movingAverage: null,
  isMovingAverage: false,
};
/**
 * All actions related to Trend Time widget feature are defined here
 */
export const trendSlice = createSlice({
  name: SLICE.TREND,
  initialState,
  reducers: {
    setIsCumulative(state, action) {
      state.isCumulative = action?.payload;
    },
    setReservationCountIsTable(state, action) {
      state.reservationCountIsTableView = action.payload;
    },
    setNumberOfCancellationIsTable(state, action) {
      state.numberOfCancellationIsTableView = action.payload;
    },
    setTrendTimeAlignment(state, action) {
      state.trendTimeAlignment = action?.payload;
    },
    setTrendTimeAlignmentToggle(state, action) {
      state.trendTimeAlignmentToggle = action?.payload;
    },
    setTrendTimeDateComparison(state, action) {
      state.trendTimeDateComparison = action?.payload;
    },
    updateTrendTimeFilterList(state, action) {
      state.trendTimeFilterList = action?.payload;
    },
    updateTrendTimeTabularFilterList(state, action) {
      state.trendTimeTabularFilterList = action?.payload;
    },
    setTrendTimeCustomDate(state, action) {
      state.trendTimeCustomDate = action?.payload;
    },
    setTrendTimeWeeklyView(state, action) {
      state.trendTimeWeeklyView = action?.payload;
    },
    setTrendTimeStartDate(state, action) {
      state.trendTimeStartDate = action?.payload;
    },
    setTrendTimeEndDate(state, action) {
      state.trendTimeEndDate = action?.payload;
    },
    setTrendTimeDateSelected(state, action) {
      state.trendTimeDateSelected = action?.payload;
    },
    setTotalRevenueIsTable(state, action) {
      state.totalRevenueIsTableView = action.payload;
    },
    setTotalOccupiedRoomIsTable(state, action) {
      state.totalOccupiedRoomIsTableView = action.payload;
    },
    setAverageDailyRateIsTable(state, action) {
      state.averageDailyRateIsTableView = action.payload;
    },
    setAverageBookingWindowIsTable(state, action) {
      state.averageBookingWindowIsTableView = action.payload;
    },
    setAverageLengthOfStayIsTable(state, action) {
      state.averageLengthOfStayIsTableView = action.payload;
    },
    setTrendTimeUrl(state, action) {
      state.trendTimeUrl = action.payload;
    },
    setTrendSymmetricComparisonToggle(state, action) {
      state.trendSymmetricComparisonToggle = action?.payload;
    },
    setTrendHotelFocusOn(state, action) {
      state.trendHotelFocusOn = action?.payload;
      state.enableSecondaryHotelGroups = action?.payload && 'hotels' in action.payload;
    },
    setIsTrendHotelAggregate(state, action) {
      state.isTrendHotelAggregate = action?.payload;
    },
    setTrendHotelNormalizedView(state, action) {
      state.trendHotelNormalizedView = action?.payload;
    },
    setTrendHotelNormalizedFilterList(state, action) {
      state.trendHotelNormalizedFilterList = action?.payload;
    },
    updateTrendHotelFilterList(state, action) {
      state.trendHotelFilterList = action?.payload;
    },
    updateTrendHotelTabularFilterList(state, action) {
      state.trendHotelTabularFilterList = action?.payload;
    },
    setEnableSecondaryHotelGroups(state, action) {
      state.enableSecondaryHotelGroups = action.payload;
    },
    setTrendSegmentFocusOn(state, action) {
      state.trendSegmentFocusOn = action?.payload;
    },
    setSegmentGroup1(state, action) {
      state.segmentGroup1 = action?.payload;
    },
    setSegmentGroup2(state, action) {
      state.segmentGroup2 = action?.payload;
    },
    setTrendSegmentChartBreakdown(state, action) {
      state.trendSegmentChartBreakdown = action?.payload;
    },
    setIsSegmentDetailed(state, action) {
      state.isSegmentDetailed = action?.payload;
    },
    setShowCustomGroupingFilterName(state, action) {
      state.showCustomGroupingFilterName = action?.payload;
    },
    updateTrendSegmentFilterList(state, action) {
      state.trendSegmentFilterList = action?.payload;
    },
    updateTrendSegmentTabularFilterList(state, action) {
      state.trendSegmentTabularFilterList = action?.payload;
    },
    setActiveTrendTab(state, action) {
      state.activeTrendTab = action.payload;
    },
    setRevenueIsDisplayTT(state, action) {
      state.revenueIsDisplayTT = action?.payload;
    },
    setOccupiedRoomIsDisplayTT(state, action) {
      state.occupiedRoomsIsDisplayTT = action?.payload;
    },
    setAdrIsDisplayTT(state, action) {
      state.adrIsDisplayTT = action?.payload;
    },
    setAvgBookingWindowIsDisplayTT(state, action) {
      state.avgBookingWindowIsDisplayTT = action?.payload;
    },
    setAvgLosIsDisplayTT(state, action) {
      state.avgLosIsDisplayTT = action?.payload;
    },
    setCxlIsDisplayTT(state, action) {
      state.cxlIsDisplayTT = action?.payload;
    },
    setRevenueIsDisplayTH(state, action) {
      state.revenueIsDisplayTH = action?.payload;
    },
    setOccupiedRoomIsDisplayTH(state, action) {
      state.occupiedRoomsIsDisplayTH = action?.payload;
    },
    setAdrIsDisplayTH(state, action) {
      state.adrIsDisplayTH = action?.payload;
    },
    setAvgBookingWindowIsDisplayTH(state, action) {
      state.avgBookingWindowIsDisplayTH = action?.payload;
    },
    setAvgLosIsDisplayTH(state, action) {
      state.avgLosIsDisplayTH = action?.payload;
    },
    setCxlIsDisplayTH(state, action) {
      state.cxlIsDisplayTH = action?.payload;
    },
    setRevenueIsDisplayTS(state, action) {
      state.revenueIsDisplayTS = action?.payload;
    },
    setOccupiedRoomIsDisplayTS(state, action) {
      state.occupiedRoomsIsDisplayTS = action?.payload;
    },
    setAdrIsDisplayTS(state, action) {
      state.adrIsDisplayTS = action?.payload;
    },
    setAvgBookingWindowIsDisplayTS(state, action) {
      state.avgBookingWindowIsDisplayTS = action?.payload;
    },
    setAvgLosIsDisplayTS(state, action) {
      state.avgLosIsDisplayTS = action?.payload;
    },
    setCxlIsDisplayTS(state, action) {
      state.cxlIsDisplayTS = action?.payload;
    },
    setTabularView(state, action) {
      state.tabularView = action?.payload;
    },
    setIsTrendForecast(state, action) {
      state.isTrendForecast = action?.payload;
    },
    setIsForecastDisabled(state, action) {
      state.isForecastDisabled = action?.payload;
    },
    setSegmentSetAName(state, action) {
      state.segmentSetAName = action?.payload;
    },
    setSegmentSetBName(state, action) {
      state.segmentSetBName = action?.payload;
    },
    setSegmentSetAQuery(state, action) {
      state.segmentSetAQuery = action.payload;
    },
    setSelectedFilterName(state, action) {
      state.selectedFilterName = action.payload;
    },
    setSegmentSetBQuery(state, action) {
      state.segmentSetBQuery = action.payload;
    },
    getAggregationFilter(state) {
      state.loading = true;
    },
    getAggregationFilterSucceeded(state, action) {
      state.loading = false;
      state.savedAggregationFilters = action?.payload[0];
    },
    getAggregationFilterFailed(state, action) {
      state.loading = false;
      if (action?.payload?.message === ERROR_MESSAGE_TYPES.USER_CONFIGURATION_NOT_FOUND) {
        state.isUserConfigurationNotExist = true;
      }
    },
    saveSegmentFilter() {},
    saveSegmentFilterSucceeded(state, action) {
      state.loading = false;
      // update existing group 
      state.savedAggregationFilters.totalData = state?.savedAggregationFilters.totalData?.map(
        (object) => {
          const updatedGroup = action?.payload?.[0]?.totalData?.find(
            (updatedObject) =>
              updatedObject?.queryPersist?.filterName === object?.queryPersist?.filterName
          );
          if (updatedGroup) return updatedGroup;
          return object;
        }
      );
      // Add new aggregation groups if not found in the state
      const existingGroupNames = state.savedAggregationFilters.totalData?.map(
        (obj) => obj?.queryPersist?.filterName
      );
      action?.payload?.[0]?.totalData?.forEach((updatedObject) => {
        if (!existingGroupNames?.includes(updatedObject?.queryPersist?.filterName)) {
          state?.savedAggregationFilters.totalData?.push(updatedObject);
        }
      });
    },
    saveSegmentFilterFailed(state) {
      state.loading = false;
    },
    getFilterNameValidation(state) {
      state.loading = true;
    },
    getFilterNameValidationSucceeded(state, action) {
      state.loading = false;
      state.existedFilter = action?.payload;
    },
    getFilterNameValidationFailed(state) {
      state.loading = false;
    },
    resetFilterNameValidationStatus(state) {
      state.existedFilter = null;
    },
    deleteAggregationFilter(state) {
      state.loading = true;
    },
    deleteAggregationFilterSucceeded(state, action) {
      state.loading = false;
      const params = new URLSearchParams(action.payload.data.query);
      const filterName = params.get('filterName');
      state.savedAggregationFilters.totalData = state?.savedAggregationFilters?.totalData?.filter(
        (object) => object?.queryPersist?.filterName !== filterName
      );
    },
    deleteAggregationFilterFailed(state) {
      state.loading = false;
    },
    setSymmetricComparisonIsDisplay(state, action) {
      state.symmetricComparisonIsDisplay = action?.payload;
    },
    setIsPrimary(state, action) {
      state.isPrimary = action?.payload;
    },
    setIsTrendPercentageChange(state, action) {
      state.isTrendPercentageChange = action?.payload;
    },
    setIsTrendPercentageChangeDisabled(state, action) {
      state.isPercentageChangeDisabled = action?.payload;
    },
    setIsTrendDeAggregate(state, action) {
      state.isTrendDeAggregate = action?.payload;
    },
    setIsTrendDeAggregateDisabled(state, action) {
      state.isTrendDeAggregateDisabled = action?.payload;
    },
    setAdjustedStartDate(state, action) {
      state.adjustedStartDate = action?.payload;
    },
    setAdjustedEndDate(state, action) {
      state.adjustedEndDate = action?.payload;
    },
    setGranularity(state, action) {
      state.granularity = action?.payload;
    },
    setTrendTimeWeeklyViewDisabled(state, action) {
      state.isTrendTimeWeeklyViewDisabled = action?.payload;
    },
    setMovingAverage(state, action) {
      state.movingAverage = action?.payload;
    },
    setIsMovingAverage(state, action) {
      state.isMovingAverage = action?.payload;
    },
  },
  //
  extraReducers: (builder) => {
    builder
      .addCase(signInActions.signIn, (state) => ({
        ...state,
        ...initialState,
      }))
      .addCase(dashboardActions.getVisualFiltersSucceeded, (state, action) => {
        const dateComparisonFilter = action?.payload?.results?.find(
          (filter) => filter?.tags?.includes(TAGS.DATE_COMPARISON) && filter?.isDefault === true
        );
        const breakdown = action?.payload?.results?.find(
          (filter) => filter?.tags?.includes(TAGS.SEGMENT_BREAKDOWN) && filter?.isDefault === true
        )?.id;
        const movingAverage = action?.payload?.results?.find(
          (filter) => filter?.tags?.includes(TAGS.MOVING_AVERAGE) && filter?.isDefault === true
        );
        return {
          ...state,
          trendTimeAlignment: {
            id: dateComparisonFilter?.id,
            label: capitalizeFirst(dateComparisonFilter?.type),
          },
          trendTimeAlignmentToggle: dateComparisonFilter?.type === ALIGNED,
          trendTimeDateComparison: dateComparisonFilter?.values?.filter(
            (filter) => filter.isDefault === true
          )[0].value,
          trendSegmentChartBreakdown: breakdown,
          movingAverage: { id: movingAverage?.id, label: movingAverage?.values[0] },
        };
      })
      .addCase(dashboardActions.getHotelListSucceeded, (state, action) => {
        const hotel = getHotelFocusOnValue(state.isDemoUser, action?.payload);
        return {
          ...state,
          trendHotelFocusOn: hotel?.focusOnValues?.[0],
        };
      })
      .addCase(dashboardActions.getVisualsSucceeded, (state, action) => {
        const visualDetails = action?.payload?.results;
        return {
          ...state,
          trendSegmentVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_SEGMENT)
          ),
          trendSegmentWeeklyVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.WEEKLY_TREND_SEGMENT)
          ),
          trendSegmentTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_SEGMENT_TABULAR_DAILY_COMBINED)
          ),
          trendSegmentWeeklyTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_SEGMENT_TABULAR_WEEKLY_COMBINED)
          ),
          trendSegmentCumulativeTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_SEGMENT_TABULAR_CUMULATIVE_COMBINED)
          ),
          trendSegmentCumulativeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_SEGMENT_CUMULATIVE)
          ),
          trendTimeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME)
          ),
          trendTimeWeeklyVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.WEEKLY_TREND_TIME)
          ),
          trendTimeTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_TABULAR_DAILY)
          ),
          trendTimeWeeklyTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_TABULAR_WEEKLY_COMBINED)
          ),
          trendTimeCumulativeTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_TABULAR_CUMULATIVE_COMBINED)
          ),
          trendTimeCumulativeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_CUMULATIVE)
          ),
          trendTimeMovingAverageVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_MOVING_AVERAGE)
          ),
          trendTimeMovingAverageTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_MOVING_AVERAGE_TABULAR_DAILY)
          ),
          trendHotelVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL)
          ),
          trendHotelWeeklyVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.WEEKLY_TREND_HOTEL)
          ),
          trendHotelTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_TABULAR_COMBINED)
          ),
          trendHotelWeeklyTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_TABULAR_WEEKLY_COMBINED)
          ),
          trendHotelCumulativeTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_TABULAR_CUMULATIVE_COMBINED)
          ),
          trendHotelCumulativeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_CUMULATIVE)
          ),
          trendTimePercentageVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_PERCENTAGE)
          ),
          trendTimePercentageTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_PERCENTAGE_TABULAR_COMBINED)
          ),
          trendSegmentPercentageVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_SEGMENT_PERCENTAGE)
          ),
          trendSegmentPercentageTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_SEGMENT_PERCENTAGE_TABULAR_COMBINED)
          ),
          trendSegmentMovingAverageVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_SEGMENT_MOVING_AVERAGE)
          ),
          trendSegmentMovingAverageTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_SEGMENT_MOVING_AVERAGE_TABULAR_DAILY)
          ),
          trendHotelPercentageVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_PERCENTAGE)
          ),
          trendHotelPercentageTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_PERCENTAGE_TABULAR_COMBINED)
          ),
          trendTimeSegmentVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_SEGMENT)
          ),
          trendTimeSegmentWeeklyVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.WEEKLY_TREND_TIME_SEGMENT)
          ),
          trendTimeSegmentTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_SEGMENT_TABULAR_DAILY_COMBINED)
          ),
          trendTimeSegmentWeeklyTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_SEGMENT_TABULAR_WEEKLY_COMBINED)
          ),
          trendTimeSegmentCumulativeTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_SEGMENT_TABULAR_CUMULATIVE_COMBINED)
          ),
          trendTimeSegmentCumulativeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_TIME_SEGMENT_CUMULATIVE)
          ),
          //
          trendHotelForecastOffVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_FORECAST_OFF)
          ),
          trendHotelForecastOffWeeklyVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.WEEKLY_TREND_HOTEL_FORECAST_OFF)
          ),
          trendHotelForecastOffTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_TABULAR_FORECAST_OFF)
          ),
          trendHotelForecastOffWeeklyTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.WEEKLY_TREND_HOTEL_TABULAR_FORECAST_OFF)
          ),
          trendHotelForecastOffCumulativeTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_CUMULATIVE_TABULAR_FORECAST_OFF)
          ),
          trendHotelForecastOffCumulativeVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_CUMULATIVE_FORECAST_OFF)
          ),
          trendHotelForecastOffPercentageVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_PERCENTAGE_FORECAST_OFF)
          ),
          trendHotelForecastOffPercentageTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_PERCENTAGE_TABULAR_FORECAST_OFF)
          ),
          trendHotelMovingAverageVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_MOVING_AVERAGE)
          ),
          trendHotelMovingAverageTabularVisuals: visualDetails?.filter((visual) =>
            visual?.tags?.includes(REPORT_TYPE.TREND_HOTEL_MOVING_AVERAGE_TABULAR_DAILY)
          ),
        };
      })
      .addCase(authActions.updateOrganizationSucceeded, (state, action) => ({
        ...state,
        isForecastDisabled: !action?.payload.enableForecastFilter,
      }))
      .addCase(dashboardActions.setHotelName, (state, action) => ({
        ...state,
        trendHotelFocusOn: action?.payload,
        trendHotelNormalizedView: false,
        enableSecondaryHotelGroups: action?.payload && 'hotels' in action.payload,
      }))
      .addCase(dashboardActions.setEnableHotelGroups, (state, action) => ({
        ...state,
        enableSecondaryHotelGroups: action?.payload,
        trendHotelNormalizedView: false,
      }))
      .addCase(dashboardActions.setSelectedHotelGroup, (state, action) => {
        let secondaryHotelSet;
        if (state && state.trendHotelFocusOn && 'hotels' in state.trendHotelFocusOn) {
          if (!isEmpty(state.hotelName) && action?.payload?.hotelGroupDropdownList?.length === 0) {
            secondaryHotelSet = action?.payload?.hotelList[0];
          } else if (
            state.trendHotelFocusOn &&
            action?.payload?.hotelGroupDropdownList?.length > 0
          ) {
            const newGroup = action?.payload?.hotelGroupDropdownList?.find(
              (set) => set?.id === state.trendHotelFocusOn?.id
            );
            if (newGroup) {
              secondaryHotelSet = newGroup;
            } else {
              secondaryHotelSet = action?.payload?.hotelList[0];
            }
          } else {
            secondaryHotelSet = action?.payload?.hotelList[0];
          }
          return {
            ...state,
            trendHotelFocusOn: secondaryHotelSet,
            enableSecondaryHotelGroups: secondaryHotelSet && 'hotels' in secondaryHotelSet,
          };
        }
        return {
          ...state,
        };
      });
  },
});
//
export const { actions: trendActions } = trendSlice;
