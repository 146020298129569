import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useIsAuthenticated from 'modules/common/auth/hooks/use-is-authenticated';
import { transformIntoArray } from 'modules/common/utils/array';
import {
  EXECUTION_TYPE,
  UiController,
  consoleCommands,
  decodedTabs,
  tabs,
} from 'modules/common/ui-controller';
import { DEMO_ROLE, USER_ROLE } from 'modules/common/constants/roles';
import ROUTES from 'modules/common/constants/route';
import { selectIsLandingPageUpdated, selectLandingPage } from 'modules/users/selectors';
import { executeMultipleCommands } from 'modules/common/ui-controller/utils/functions';
import { dashboardActions } from '../slice';
import {
  selectHotelChangeState,
  selectIsSavedCommandFilterLoaded,
  selectTabChangeState,
  selectUserRole,
  selectVisualFilters,
} from '../selectors';

/**
 * Custom hook to execute custom landing page state
 */
const useCustomLanding = (setTabValue) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useIsAuthenticated();
  const customLandingRef = useRef(null);
  const tabChange = useSelector(selectTabChangeState);
  const hotelChange = useSelector(selectHotelChangeState);
  const userRole = useSelector(selectUserRole);
  const customLandingPage = useSelector(selectLandingPage);
  const isLandingPageUpdated = useSelector(selectIsLandingPageUpdated);
  const visualFilters = useSelector(selectVisualFilters);
  const IsSavedCommandFilterLoaded = useSelector(selectIsSavedCommandFilterLoaded);
  //
  const [isValidUrl, setIsValidUrl] = useState(true);
  //
  customLandingRef.current = () => {
    // retrieve search string from url
    const decode = customLandingPage?.landingPage?.split('#')[0];
    // execute url filter options
    executeMultipleCommands(decode, EXECUTION_TYPE.CONVERSATION);
  };
  //
  useEffect(() => {
    // call for command execution function after tab and hotel commands are executed
    if (
      tabChange &&
      hotelChange &&
      customLandingPage &&
      Boolean(customLandingPage?.landingPage) &&
      Boolean(!location?.search) &&
      !isLandingPageUpdated &&
      !IsSavedCommandFilterLoaded
    ) {
      customLandingRef.current();
    }
  }, [tabChange, hotelChange]);

  useEffect(() => {
    if (
      isAuthenticated() &&
      customLandingPage &&
      Boolean(customLandingPage?.landingPage) &&
      Boolean(!location?.search) &&
      isValidUrl &&
      !isLandingPageUpdated &&
      !IsSavedCommandFilterLoaded &&
      visualFilters
    ) {
      const [search, hash] = customLandingPage.landingPage.split('#');
      if (hash) {
        dispatch(dashboardActions.setTabChange(false));
        const tab = decodedTabs[hash];
        if (tab !== undefined) {
          if (isAuthenticated()) {
            if (
              tab === decodedTabs['i-prefer'] &&
              (userRole === USER_ROLE || userRole === DEMO_ROLE)
            ) {
              navigate(ROUTES.DASHBOARD);
              setIsValidUrl(false);
              return;
            }
            setTabValue(tabs[tab]);
            dispatch(dashboardActions.setTabChange(true));
            setIsValidUrl(true);
          }
        }
      }
      // retrieve hotel command and set hotel name
      if (search) {
        dispatch(dashboardActions.setHotelChange(false));
        const data = search;
        const commands = data?.split('&');
        const hotel = commands?.filter((com) => com.includes(consoleCommands.HOTEL));
        const hotelGroup = commands?.filter((com) => com.includes(consoleCommands.HOTEL_GROUP));
        if (hotel?.length > 0) {
          // assign hotel name value into array
          const values = transformIntoArray(hotel[0]?.split('=')[1]);
          UiController.setHotelName(values);
        } else if (hotelGroup?.length > 0) {
          const groupValues = transformIntoArray(hotelGroup[0]?.split('=')[1]);
          UiController.setHotelGroupName(groupValues);
        }
      }
    }
  }, [customLandingPage, isLandingPageUpdated, visualFilters, location]);
};

export default useCustomLanding;
