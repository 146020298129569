import { VISUAL_HEADER_TYPES } from 'modules/dashboard/constants';
import mapper from './mapper';

const withSingleHeader = (result, fileName) => {
  // Split data set by new line character and create a new array removing the first row
  const parsedData = result?.data.split('\r\n').slice(1);
  const metrics = mapper[fileName].metrics.filter((item) => result?.data.includes(item));
  let csv = 'Metric,';

  // set the sub headers
  csv += `${mapper[fileName].groupBy},`;
  csv += metrics.join(',');

  const groupedData = {};
  parsedData.forEach((item) => {
    item = item.split(',');
    if (groupedData[item[0]] === undefined) {
      groupedData[item[0]] = {};
    }
    // eslint-disable-next-line prefer-destructuring
    groupedData[item[0]][item[1]] = item[2];
  });

  const concatnatedData = [];
  Object.keys(groupedData).forEach((day) => {
    let str = day;
    metrics.forEach((item) => {
      if (day) {
        const value = groupedData[day][item];
        // eslint-disable-next-line no-unused-expressions
        value !== undefined ? (str += `,${value}`) : (str += `,""`);
      }
    });
    concatnatedData.push(str);
  });

  csv += '\r\n';
  csv += concatnatedData.join('\r\n');

  return csv;
};

const withGroupedDoubleHeaders = (result, fileName) => {
  let csv = '';
  const parsedData = result?.data.split('\r\n').slice(1);

  const metrics = {};
  const groupedData = {};
  parsedData.forEach((item) => {
    const row = item.split(',');

    // record the metrics and sub headings
    if (metrics[row[2]] === undefined) {
      metrics[row[2]] = '';
    }
    // add the metric if not included
    if (!metrics[row[2]].includes(row[3])) metrics[row[2]] += `${row[3]},`;

    // map values to metrics and sub columns
    if (groupedData[row[0]] === undefined) {
      groupedData[row[0]] = {};
    }
    if (groupedData[row[0]][row[1]] === undefined) {
      groupedData[row[0]][row[1]] = {};
    }
    // for the lables
    if (groupedData[row[0]][row[1]][row[2]] === undefined) {
      groupedData[row[0]][row[1]][row[2]] = {};
    }

    groupedData[row[0]][row[1]][row[2]][row[3]] = item.replace(
      `${row[0]},${row[1]},${row[2]},${row[3]}`,
      ''
    );
  });

  // set the headers
  let parentHeaders = 'Metric,';
  let subHeaders = `${mapper[fileName].groupBy},`;
  Object.keys(metrics).forEach((metric) => {
    if (metric) {
      if (metric === 'undefined') return;
      // get the number of sub headers and merge the parent
      parentHeaders += metric + ','.repeat(metrics[metric].split(',').length - 1);
      subHeaders += metrics[metric];
    }
  });

  csv += `${parentHeaders}\r\n`;
  csv += `${subHeaders}\r\n`;

  // concatnate the data
  Object.keys(groupedData).forEach((item) => {
    if (item) {
      // reserve a entire line for the date or the date name 50 is a random number if lines are not aligned increasing this values should work
      csv += item + ",".repeat(50)
      csv += '\r\n'
      Object.keys(groupedData[item]).forEach((m) => {
        // if values are not inline to the columns this logic could be the issue
        const currentMetrics = Object.keys(groupedData[item][m]);
        // mark the number of the iteration and look if the values for that index is there in the current metric list
        // if not add a placeholder to reserve that space
        csv += `${m}`
        currentMetrics.forEach(currentmetric => {
          // use the metrics array to preserve the fields order
          metrics[currentmetric].slice(0, -1).split(',').forEach(orderVal => {
            if (groupedData[item][m][currentmetric][orderVal]) {
              csv += groupedData[item][m][currentmetric][orderVal]
            } else {
              csv += ","
            }
          })
        })
        csv += '\r\n'
      });
    }
  });

  return csv;
};

const withDoubleHeaders2 = (result, fileName) => {
  let csv = '';
  // Split data set by new line character and create a new array removing the first row
  const parsedData = result?.data.split('\r\n').slice(1);

  const metrics = {};
  const groupedData = {};
  parsedData.forEach((item) => {
    const row = item.split(',');

    // record the metrics and sub headings
    if (metrics[row[1]] === undefined) {
      metrics[row[1]] = '';
    }
    // add the metric if not included
    if (!metrics[row[1]].includes(row[2])) metrics[row[1]] += `${row[2]},`;

    // map values to metrics and sub columns
    if (groupedData[row[0]] === undefined) {
      groupedData[row[0]] = {};
    }
    if (groupedData[row[0]][row[1]] === undefined) {
      groupedData[row[0]][row[1]] = {};
    }
    groupedData[row[0]][row[1]][row[2]] = item.replace(`${row[0]},${row[1]},${row[2]},`, '');
  });

  // set the headers
  let parentHeaders = 'Metric,';
  let subHeaders = `${mapper[fileName].groupBy},`;
  Object.keys(metrics).forEach((metric) => {
    if (metric) {
      if (metric === 'undefined')
        return;
      // get the number of sub headers and merge the parent
      parentHeaders += metric + ','.repeat(metrics[metric].split(',').length - 1);
      subHeaders += metrics[metric];
    }
  });

  csv += `${parentHeaders}\r\n`;
  csv += `${subHeaders}\r\n`;
  // sort the array
  let sortedData;
  const groupedDataKeys = Object.keys(groupedData)
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(Object.keys(groupedData).splice(0, 10).filter(key => key !== "")[0])) {
    // eslint-disable-next-line arrow-body-style
    sortedData = groupedDataKeys.sort((a, b) => { return a - b })
  }
  else {
    sortedData = groupedDataKeys.sort()
  }

  // concatnate the data
  sortedData.forEach((item) => {
    if (item) {
      csv += item;
      Object.keys(groupedData[item]).forEach((m) => {
        // if values are not inline to the columns this logic could be the issue
        const currentMetrics = Object.keys(groupedData[item][m]);
        // mark the number of the iteration and look if the values for that index is there in the current metric list
        // if not add a placeholder to reserve that space
        metrics[m].slice(0, -1).split(',').forEach((v) => {
          // check for empty values where the columns are defined but the value is absent
          if (currentMetrics.includes(v) && v !== 'undefined') {
            // append the value to csv
            csv += `,${groupedData[item][m][v]}`;
          } else {
            csv += `,""`;
          }
        });
      });
      csv += '\r\n';
    }
  });

  return csv;
};

const withDoubleHeaders = (result, fileName) => {
  // Split data set by new line character and create a new array removing the first row
  const parsedData = result?.data.split('\r\n').slice(1);
  const metrics = Object.keys(mapper[fileName].metrics);
  let csv = '';
  // set the parent headers
  csv += `Metric,`;
  metrics.forEach((metric) => {
    csv += metric + ','.repeat(Object.keys(mapper[fileName].metrics[metric].subColumns).length);
  });
  csv += '\r\n';
  // set the sub headers
  csv += `${mapper[fileName].groupBy},`;
  metrics.forEach((metric) => {
    csv += `${mapper[fileName].metrics[metric].subColumns.join(',')},`;
  });
  // and a new line to the end
  csv += '\r\n';

  const groupedDataByMetric = {};
  // populate the array with the groups
  metrics.forEach((metric) => {
    groupedDataByMetric[metric] = [];
  });

  parsedData.forEach((row) => {
    let rowData = [];
    let currentVal = '';
    let withinQuotes = false;

    for (let i = 0; i < row.length; i += 1) {
      const char = row[i];
      // add to add this to add a blank when columns like forecast are blank to occupy space for that
      if (char === ',' && !withinQuotes) {
        rowData.push(`"${currentVal.trim()}"`);
        currentVal = '';
      } else if (char === '"') {
        withinQuotes = !withinQuotes;
      } else {
        currentVal += char;
      }
    }
    // Push the last value after the loop
    rowData.push(currentVal.trim());

    // the lables may be in not in same order in different but simillar visuals
    // eg: TVH_* -> Date,A Actls,B Actls,% Chg,A Fcsts,B Fcsts,Metric 
    //     PVH-* -> Date,Label,A Actls,B Actls,% Chg,A Fcsts,B Fcsts
    let group = ''
    if (!mapper[fileName].transformColumns) {
      group = rowData.pop()
    } else {
      // eslint-disable-next-line prefer-destructuring
      group = rowData.splice(1, 1)[0]
    }
    if (group) {
      group = group.replace(' ', '_').replaceAll('"', '')
      // make sure the currency values are wrapped with "" all the time
      rowData = rowData.map(rowValue => {
        if (rowValue.includes(",") && !rowValue.includes('"')) {
          return `"${rowValue}"`
        }
        return rowValue
      })
      groupedDataByMetric[group].push(rowData);
    }
  });
  // merge data by groups
  const concatnatedData = {};

  metrics.forEach((metric) => {
    groupedDataByMetric[metric].forEach((item) => {
      // concatnate the line using the date as the key
      // condition to prevent contactnating to undefined value
      if (concatnatedData[item[0]] === undefined) {
        concatnatedData[item[0]] = '';
      }

      // check if the item contain more columns than the desired
      // eg: for forecast a "" is appended to every metric
      while (mapper[fileName].metrics[metric].subColumns.length !== item.slice(1).length) {
        item.pop();
      }
      concatnatedData[item[0]] += `${item.slice(1).join(',')},`;
    });
  });

  // append data to the csv
  Object.keys(concatnatedData).forEach((item) => {
    csv += `${item},${concatnatedData[item]}\r\n`;
  });

  csv = csv.replaceAll('metrics_spot_MA', 'Selection')

  return csv;
};

const GenerateCSV = (result, fileName) => {
  console.log("CSV Export Started.....")
  switch (mapper[fileName].headertype) {
    case VISUAL_HEADER_TYPES.SINGLE:
      return withSingleHeader(result, fileName);
    case VISUAL_HEADER_TYPES.DOUBLE:
      return withDoubleHeaders(result, fileName);
    case VISUAL_HEADER_TYPES.DOUBLE_2:
      return withDoubleHeaders2(result, fileName);
    case VISUAL_HEADER_TYPES.GROUPED_DOUBLE:
      return withGroupedDoubleHeaders(result, fileName);
    default:
      return '';
  }
};

export default GenerateCSV;
