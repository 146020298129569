import { useState, useEffect, useRef } from 'react';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  FormControl,
  Toolbar,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectIsSignedIn,
  selectHotelDropdownList,
  selectBreakdownDetails,
  selectHotelName,
  selectUtmBreakdownDetails,
  selectEnableHotelGroups,
  selectHotelGroupDropdownList,
  selectMergedHotelList,
  selectPredefinedHotelGroups,
} from 'modules/dashboard/selectors';
import {
  selectUtmBreakdownSelection,
  selectUtmFocusOn,
  selectWebClickFocusOn,
  selectWebClickHotelFocusOn,
  selectWebClickScalingFactor,
  selectWebClickWeeklyView,
  selectWebClickCumulativeView,
  selectWebClickStep,
  selectWebClickOtb,
  selectWebClickCustomDate,
  selectWebClickEndDate,
  selectWebClickStartDate,
  selectWebClickAlignmentToggle,
  selectWebClickDateComparison,
  selectWebClickAlignment,
  selectWebClickNormalizedView,
  selectWebClickTargetDate,
  selectWebClickPaceHorizon,
  selectWebClickSymmetricComparisonToggle,
  selectWebClickBreakdown,
  selectSymmetricComparisonIsDisplay,
  selectWebClickEnableSecondaryHotelGroups,
} from 'modules/dashboard/components/tab-container/web-click-tab/selectors';
import { getComparisonDateRange } from 'modules/dashboard/functions';
import { capitalizeFirst } from 'modules/common/helpers/strings';
import { CheckboxDropdown, CustomStack, SlidingScale } from 'modules/common/components';
import { addSeconds, isSaturday, isSunday, nextSaturday, previousSunday } from 'date-fns';
import {
  TAGS,
  OTB_VALUE,
  KEYS,
  RESET_BOOKING_DATE,
  TREND_STAY_DATE,
  ALIGNED,
} from 'modules/dashboard/constants';
import ActionButton from 'modules/common/components/action-button';
import { ENVIRONMENT } from 'config';
import DataUpdatedLabel from 'modules/dashboard/components/tab-container/data-updated-label';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import WeeklyCumulativeWindow from 'modules/dashboard/components/tab-container/inner-filter/components/weekly-cumulative-window';
import BookingWindow from 'modules/dashboard/components/tab-container/inner-filter/components/booking-window';
import VsComparisonWindow from 'modules/dashboard/components/tab-container/inner-filter/components/vs-comparison-window';
import {
  getBreakdown,
  getDateComparisonFilter,
  getFocusOnFilter,
  getSubAlignmentFilter,
  getSubBreakdownFilter,
  getSubPaceFilter,
  getSubUtmBreakdownFilter,
  getUtmBreakdown,
  getUtmFocusOnFilter,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import { webClickActions } from 'modules/dashboard/components/tab-container/web-click-tab/slice';
import { CLICK_STEP_LIST } from 'modules/dashboard/components/tab-container/web-click-tab/constants';
import useWebClickCommandFlow from '../../hooks/use-web-click-command-flow';
import { CompareHotelsWindow } from '../../../inner-filter';
/**
 * Filters for web click widget
 * @param {String} reportType  - Report type of active tab
 * @param {Object} visual - Symmetric comparison visual object
 * @param {String} hotelTimezone- Timezone of selected hotel
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {Date} isLoaded - Visual is loaded state
 * @param {String} visualName - Display name for symmetric comparison visual
 * @returns
 */
const WebClickFilter = ({
  reportType,
  visual,
  latestDate,
  hotelTimezone,
  isLoaded,
  visualName,
}) => {
  const dispatch = useDispatch();
  //
  const setCustomDatesRef = useRef(null);
  // Selected filter values are obtained from redux store. Refer each selector function for details.
  const mergedHotelList = useSelector(selectMergedHotelList);
  const alignment = useSelector(selectWebClickAlignment);
  const dateComparison = useSelector(selectWebClickDateComparison);
  const visualFilters = useSelector(selectVisualFilters);
  const targetDate = useSelector(selectWebClickTargetDate);
  const paceHorizon = useSelector(selectWebClickPaceHorizon);
  const hotelList = useSelector(selectHotelDropdownList);
  const alignmentToggle = useSelector(selectWebClickAlignmentToggle);
  const symmetricComparisonToggle = useSelector(selectWebClickSymmetricComparisonToggle);
  const isSignIn = useSelector(selectIsSignedIn);
  const customBooking = useSelector(selectWebClickOtb);
  const customDate = useSelector(selectWebClickCustomDate);
  const startDate = useSelector(selectWebClickStartDate);
  const endDate = useSelector(selectWebClickEndDate);
  const normalizedViewToggle = useSelector(selectWebClickNormalizedView);
  const chartBreakdown = useSelector(selectWebClickBreakdown);
  const focusOn = useSelector(selectWebClickFocusOn);
  const breakdownData = useSelector(selectBreakdownDetails);
  const selectedHotelName = useSelector(selectHotelName);
  const hotelFocusOn = useSelector(selectWebClickHotelFocusOn);
  const scalingFactor = useSelector(selectWebClickScalingFactor);
  const webClickStep = useSelector(selectWebClickStep);
  const weeklyViewToggle = useSelector(selectWebClickWeeklyView);
  const isCumulative = useSelector(selectWebClickCumulativeView);
  const utmBreakdownData = useSelector(selectUtmBreakdownDetails);
  const utmBreakdown = useSelector(selectUtmBreakdownSelection);
  const utmFocusOn = useSelector(selectUtmFocusOn);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const symmetricComparisonIsDisplay = useSelector(selectSymmetricComparisonIsDisplay);
  const hotelGroupList = useSelector(selectHotelGroupDropdownList);
  const enableSecondaryHotelGroups = useSelector(selectWebClickEnableSecondaryHotelGroups);
  const predefinedHotelGroups = useSelector(selectPredefinedHotelGroups);
  //
  const [dateComparisonList, setDateComparisonList] = useState([]);
  const [paceList, setPaceList] = useState([]);
  const [chartBreakdownList, setChartBreakdownList] = useState([]);
  const [focusOnList, setFocusOnList] = useState([]);

  // To select otb or custom booking window
  const [scaleInputValue, setScaleInputValue] = useState(Number(scalingFactor));
  const [triggerTime, setTriggerTime] = useState();
  const [utmBreakdownList, setUtmBreakdownList] = useState([]);
  const [utmFocusOnList, setUtmFocusOnList] = useState([]);
  //
  const triggerTimeRef = useRef(triggerTime);
  triggerTimeRef.current = triggerTime;
  const scaleRef = useRef(null);
  //
  const handleOTBChange = (event) => {
    const { value } = event.target;
    dispatch(webClickActions.setWebClickOtb(value !== 'false'));
    dispatch(webClickActions.setWebClickBookingDate(RESET_BOOKING_DATE));
  };

  // Reset button function to set filter panel values to default
  const clearSearch = () => {
    dispatch(webClickActions.setWebClickBreakdownFocusOn([]));
    dispatch(webClickActions.setWebClickScalingFactor(1));
    dispatch(webClickActions.setWebClickStep([CLICK_STEP_LIST[0]]));
    dispatch(webClickActions.setWebClickOtb(false));
    dispatch(
      webClickActions.setWebClickPaceHorizon(
        visualFilters?.filter(
          (filter) => filter?.tags?.includes(TAGS.PACE_HORIZON) && filter?.isDefault === true
        )[0]?.id
      )
    );
    const subAlignmentFilter = visualFilters?.filter(
      (filter) => filter?.tags?.includes(TAGS.DATE_COMPARISON) && filter?.isDefault === true
    )[0];
    dispatch(
      webClickActions.setWebClickDateComparison(
        subAlignmentFilter?.values?.filter((filter) => filter.isDefault === true)[0].value
      )
    );
    dispatch(
      webClickActions.setSelectedUtmBreakdown(
        visualFilters?.find(
          (filter) => filter?.tags?.includes(TAGS.UTM_BREAKDOWN) && filter?.isDefault === true
        )?.id
      )
    );
    dispatch(webClickActions.setWebClickBookingDate(RESET_BOOKING_DATE));
    dispatch(webClickActions.setWebClickAlignmentToggle(subAlignmentFilter?.type === ALIGNED));
    dispatch(webClickActions.setWebClickSymmetricComparisonToggle(true));
    dispatch(webClickActions.setWebClickStartDate(TREND_STAY_DATE.startDate));
    dispatch(webClickActions.setWebClickEndDate(TREND_STAY_DATE.endDate));
    dispatch(webClickActions.setWebClickCustomDate(KEYS.START_DATE));
    dispatch(webClickActions.setWebClickDateSelected(TREND_STAY_DATE.startDate));
    dispatch(webClickActions.setWebClickNormalizedView(false));
    dispatch(webClickActions.setWebClickCumulativeView(false));
    dispatch(webClickActions.setWebClickWeeklyView(false));
    dispatch(webClickActions.setUtmFocusOn([]));
    dispatch(webClickActions.setWebClickScalingFactor(1));
    setScaleInputValue(1);
  };

  // Triggered on change of alignment, visualFilters or alignmentToggle
  useEffect(() => {
    // Set date comparison list based on alignment
    if (alignment) {
      const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
      setDateComparisonList(dateComparisonFilter);
      // persist value on browser refresh
      const serializedState = JSON.parse(
        localStorage.getItem(`persist:${ENVIRONMENT.PERSIST_KEY}`)
      );
      if (!isSignIn && serializedState) {
        return;
      }
      dispatch(
        webClickActions.setWebClickDateComparison(
          dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
        )
      );
    }
  }, [alignment, visualFilters, alignmentToggle]);

  // Triggered on change of alignmentToggle or visualFilters
  useEffect(() => {
    // Set alignment list
    const subAlignmentFilter = getSubAlignmentFilter(visualFilters, alignmentToggle);
    dispatch(
      webClickActions.setWebClickAlignment({
        id: subAlignmentFilter?.id,
        label: capitalizeFirst(subAlignmentFilter?.type),
      })
    );
  }, [alignmentToggle, visualFilters]);

  // Alignment toggle change handler
  const handleToggleChange = (event) => {
    dispatch(webClickActions.setWebClickAlignmentToggle(event.target.checked));
    const dateComparisonFilter = getDateComparisonFilter(visualFilters, alignment?.id);
    dispatch(
      webClickActions.setWebClickDateComparison(
        dateComparisonFilter?.filter((filter) => filter?.isDefault === true)[0].value
      )
    );
  };

  // Triggered on change of customBooking, dateComparison, visualFilters
  useEffect(() => {
    // Assign default value to date comparison when OTB is selected for booking window
    if (!customBooking && dateComparison === OTB_VALUE) {
      const subAlignmentFilter = getSubAlignmentFilter(visualFilters, true);
      dispatch(
        webClickActions.setWebClickDateComparison(
          subAlignmentFilter?.values?.filter((filter) => filter.isDefault === true)[0].value
        )
      );
    }
  }, [customBooking, dateComparison, visualFilters]);

  // Date comparison change handler
  const handleComparisonChange = (event) => {
    dispatch(webClickActions.setWebClickDateComparison(event.target.value));
    dispatch(webClickActions.setWebClickDateSelected(TREND_STAY_DATE.startDate));
    dispatch(webClickActions.setWebClickCustomDate(KEYS.START_DATE));
    //
    const dateRange = getComparisonDateRange(
      TREND_STAY_DATE.startDate,
      targetDate.startDate,
      targetDate.endDate,
      KEYS.START_DATE
    );
    //
    dispatch(webClickActions.setWebClickStartDate(dateRange?.comparisonStartDate.toISOString()));
    dispatch(webClickActions.setWebClickEndDate(dateRange?.comparisonEndDate.toISOString()));
  };
  // Triggered on change of visualFilters
  useEffect(() => {
    // Set pace filter list, utm breakdown list and breakdown list
    const subPaceFilter = getSubPaceFilter(visualFilters);
    if (subPaceFilter) {
      setPaceList(subPaceFilter);
    }
    const subBreakdownFilter = getSubBreakdownFilter(visualFilters, TAGS.WEB_CLICK);
    if (subBreakdownFilter) {
      setChartBreakdownList(subBreakdownFilter);
    }
    const subUtmBreakdownFilter = getSubUtmBreakdownFilter(visualFilters);
    if (subUtmBreakdownFilter) {
      setUtmBreakdownList(subUtmBreakdownFilter);
    }
  }, [visualFilters]);

  // Triggered on change of chartBreakdown, visualFilters, breakdownData
  useEffect(() => {
    //  Set focus on list relevant for selected chart breakdown
    if (chartBreakdown) {
      const breakdown = getBreakdown(visualFilters, chartBreakdown);
      const focusOnFilter = getFocusOnFilter(breakdownData, breakdown);
      setFocusOnList(focusOnFilter);
    }
  }, [chartBreakdown, visualFilters, breakdownData]);
  //
  useEffect(() => {
    if (utmBreakdown) {
      const breakdown = getUtmBreakdown(visualFilters, utmBreakdown);
      const focusOnFilter = getUtmFocusOnFilter(utmBreakdownData, breakdown);
      setUtmFocusOnList(focusOnFilter);
    }
  }, [utmBreakdown, visualFilters, utmBreakdownData]);
  //
  const focusOnLabel = focusOn?.length ? 'Focused on' : 'Select Focus on';

  // Get persisted values for hotel name and stay date
  const utmFocusOnLabel = utmFocusOn?.length ? 'Focused on' : 'Select Focus on';
  const clickStepLabel = webClickStep?.length ? 'Web Click Steps' : 'Select Web Click Steps';

  // Triggered on change of targetDate, weeklyViewToggle
  useEffect(() => {
    // Set stay dates based on weekly toggle
    if (weeklyViewToggle) {
      if (isSunday(new Date(targetDate.startDate))) {
        dispatch(
          webClickActions.setWeeklyWebClickStartDate(new Date(targetDate.startDate).toISOString())
        );
      } else {
        dispatch(
          webClickActions.setWeeklyWebClickStartDate(
            previousSunday(new Date(targetDate.startDate)).toISOString()
          )
        );
      }
      if (isSaturday(new Date(targetDate.endDate))) {
        dispatch(
          webClickActions.setWeeklyWebClickEndDate(new Date(targetDate.endDate).toISOString())
        );
      } else {
        dispatch(
          webClickActions.setWeeklyWebClickEndDate(
            nextSaturday(new Date(targetDate.endDate)).toISOString()
          )
        );
      }
    }
  }, [targetDate, weeklyViewToggle]);
  // Set min and max values for scaling factor
  useEffect(() => {
    if (scaleInputValue < 1) setScaleInputValue(1);
    if (scaleInputValue > 100) setScaleInputValue(100);
  }, [scaleInputValue]);
  // set scale factor value with delay
  scaleRef.current = () => {
    setTimeout(() => {
      const currentTime = new Date();
      if (currentTime.getTime() - triggerTimeRef.current.getTime() > 999) {
        dispatch(webClickActions.setWebClickScalingFactor(Number(scaleInputValue)));
      }
    }, Number(ENVIRONMENT.TIMEOUT_DELAY));
  };
  //
  useEffect(() => {
    if (scaleRef?.current && triggerTimeRef?.current) {
      scaleRef?.current();
    }
  }, [scaleInputValue, triggerTime]);
  // reset focus on option when hotel groups option is selected
  useEffect(() => {
    dispatch(webClickActions.setWebClickBreakdownFocusOn([]));
  }, [enableHotelGroups]);
  // Web click command flow implementation
  useWebClickCommandFlow(
    isLoaded,
    customBooking,
    paceList,
    handleToggleChange,
    handleComparisonChange,
    focusOnList,
    hotelList,
    selectedHotelName,
    setCustomDatesRef,
    dateComparison,
    selectHotelName,
    visualFilters,
    alignment,
    dateComparisonList,
    [...hotelGroupList, ...predefinedHotelGroups],
    utmFocusOnList,
    utmBreakdownList,
    setScaleInputValue
  );
  //
  return (
    <>
      <BookingWindow
        vOTB={customBooking}
        customBooking={customBooking}
        handleOTBChange={handleOTBChange}
        reportType={reportType}
        latestDate={latestDate}
        otbValue={false}
      />
      <br />
      <WeeklyCumulativeWindow
        isCumulative={isCumulative}
        weeklyViewToggle={weeklyViewToggle}
        handleIsCumulativeChange={(event) =>
          dispatch(webClickActions.setWebClickCumulativeView(event.target.checked))
        }
        handleWeeklyViewChange={(event) =>
          dispatch(webClickActions.setWebClickWeeklyView(event.target.checked))
        }
      />
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <CheckboxDropdown
          options={CLICK_STEP_LIST}
          values={webClickStep ?? []}
          label={clickStepLabel}
          onchange={(event, value) => dispatch(webClickActions.setWebClickStep(value))}
          isOptionEqualTo={(option, value) => option?.id === value?.id}
        />
      </CustomStack>
      <br />
      <VsComparisonWindow
        customBooking={customBooking}
        alignmentToggle={alignmentToggle}
        handleToggleChange={handleToggleChange}
        symmetricComparisonToggle={symmetricComparisonToggle}
        visual={visual}
        visualName={visualName}
        dateComparison={dateComparison}
        dateComparisonList={dateComparisonList}
        handleComparisonChange={handleComparisonChange}
        handleSymmetricComparisonChange={(event) =>
          dispatch(webClickActions.setWebClickSymmetricComparisonToggle(event.target.checked))
        }
        isCustomOptionAvailable
        customDate={customDate}
        reportType={reportType}
        startDate={startDate}
        endDate={endDate}
        targetDate={targetDate}
        isDisplay={symmetricComparisonIsDisplay}
        isDisplayOnClickAction={() => {
          dispatch(webClickActions.setSymmetricComparisonIsDisplay(!symmetricComparisonIsDisplay));
        }}
        handleCustomDates={(event) => {
          dispatch(webClickActions.setWebClickCustomDate(event.target.value));
        }}
      />
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <FormControl>
          <FormLabel>Booking Pace Horizon</FormLabel>
          <RadioGroup
            value={paceHorizon ?? null}
            onChange={(event) => {
              dispatch(webClickActions.setWebClickPaceHorizon(event.target.value));
            }}
          >
            {paceList
              ?.sort(
                (element1, element2) =>
                  element1.label?.[0] &&
                  element2.label?.[0] &&
                  Number(element1?.label?.[0]?.split(' ')[0]) -
                    Number(element2?.label?.[0]?.split(' ')[0])
              )
              ?.map((item) => (
                <FormControlLabel
                  key={item?.id}
                  value={item?.id}
                  control={<Radio />}
                  label={item?.label}
                  sx={{
                    my: -1,
                  }}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </CustomStack>
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <FormControl>
          <FormLabel>Breakdown</FormLabel>
          <RadioGroup value={chartBreakdown ?? null}>
            {chartBreakdownList?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio />}
                label={item?.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl>
          <CheckboxDropdown
            options={focusOnList}
            values={focusOn ?? []}
            label={focusOnLabel}
            onchange={(event, value) =>
              dispatch(webClickActions.setWebClickBreakdownFocusOn(value))
            }
            isOptionEqualTo={(option, value) => option?.id === value?.id}
          />
        </FormControl>
      </CustomStack>
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <FormControl>
          <SlidingScale
            label="Scaling Factor"
            value={scaleInputValue ?? 1}
            startLabel={1}
            endLabel={100}
            onChange={(event) => {
              setTriggerTime(addSeconds(new Date().getTime(), 0));
              setScaleInputValue(Number(event.target.value));
            }}
            onInputChange={(event) => {
              setScaleInputValue(Number(event.target.value));
              setTriggerTime(addSeconds(new Date().getTime(), 0));
            }}
            innerRef={scaleRef}
          />
        </FormControl>
      </CustomStack>
      <br />
      <CompareHotelsWindow
        selectedHotelName={selectHotelName}
        hotelList={hotelList ?? []}
        mergedHotelList={mergedHotelList ?? []}
        focusOn={hotelFocusOn}
        focusOnLabel={hotelFocusOn?.length ? 'Compared with' : 'Select Hotel'}
        handleCompareHotelsChange={(event, value) => {
          dispatch(webClickActions.setWebClickHotelFocusOn(value));
        }}
        normalizedViewToggle={normalizedViewToggle ?? true}
        handleTrendHotelNormalize={(event) =>
          dispatch(webClickActions.setWebClickNormalizedView(event.target.checked))
        }
        enableSecondaryHotelGroups={enableSecondaryHotelGroups}
        hotelGroupList={hotelGroupList}
      />
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <FormControl>
          <FormLabel>UTM Breakdown</FormLabel>
          <RadioGroup
            value={utmBreakdown ?? null}
            onChange={(event) => {
              dispatch(webClickActions.setSelectedUtmBreakdown(event.target.value));
              dispatch(webClickActions.setUtmFocusOn([]));
            }}
          >
            {utmBreakdownList?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio />}
                label={item?.label}
                sx={{
                  my: -1,
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl>
          <CheckboxDropdown
            options={utmFocusOnList}
            values={utmFocusOn ?? []}
            label={utmFocusOnLabel}
            onchange={(event, value) => dispatch(webClickActions.setUtmFocusOn(value))}
            isOptionEqualTo={(option, value) => option?.id === value?.id}
          />
        </FormControl>
      </CustomStack>
      <br />
      <CustomStack cssClassName={styles.innerFilter}>
        <ActionButton onClick={clearSearch} label="RESET" />
      </CustomStack>
      <Toolbar />
      <DataUpdatedLabel latestDate={latestDate} hotelTimezone={hotelTimezone} />
    </>
  );
};
//
export default WebClickFilter;
