import {
  Autocomplete,
  FormControl,
  TextField,
  IconButton,
  Tooltip,
  Grid,
  Box,
  Divider,
  Typography,
  FormLabel,
} from '@mui/material';
import { CustomStack, CustomToggleSwitch } from 'modules/common/components';
import { AddHotelGroup } from 'modules/common/components/add-hotel-group';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
import { useEffect, useState } from 'react';
import Apartment from '@mui/icons-material/Apartment';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthUser, selectOrganizationId } from 'modules/common/auth/selectors';
import { selectHotelGroups, selectIsDemoUser } from 'modules/dashboard/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import { isEmpty } from 'modules/common/helpers/object';
import { CustomEditIcon } from './style';
/**
 * Compare hotels window component common for the dashboard component
 * @param {*} selectedHotelName - selected hotel
 * @param {Array} hotelList - hotel list
 * @param {*} focusOn - selected compared hotels
 * @param {String} focusOnLabel - label for dropdown
 * @param {Function} handleCompareHotelsChange - Function to set compare hotels value
 * @param {Boolean} isTrendHotelAggregate - state of isTrendHotelAggregate
 * @param {Boolean} aggregateDisabled - state of aggregate toggle enabled/disabled
 * @param {Function} handleTrendHotelAggregate - Function to set trend hotel aggregate value
 * @returns
 */
const CompareHotelsWindow = ({
  hotelList,
  focusOn,
  mergedHotelList,
  disableNormalizedViewToggle,
  focusOnLabel,
  handleCompareHotelsChange,
  normalizedViewToggle,
  handleTrendHotelNormalize,
  enableSecondaryHotelGroups,
  hotelGroupList,
  isTrendPercentageChange = false,
  id = '',
  isRatePlanEnabled = false
}) => {
  const dispatch = useDispatch();
  //
  const organizationId = useSelector(selectOrganizationId);
  const authUser = useSelector(selectAuthUser);
  const selectedHotelGroups = useSelector(selectHotelGroups);
  const isDemoUser = useSelector(selectIsDemoUser);
  //
  const [open, setOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [noOfRooms, setNoOfRooms] = useState(0);
  const [modifiedList, setModifiedList] = useState([]);
  // set the number of rooms per hotel/hotel set
  useEffect(() => {
    setNoOfRooms(0);
    if (!isEmpty(focusOn) && enableSecondaryHotelGroups) {
      setIsEditable(true);
      focusOn?.hotels?.forEach((hotel) => {
        setNoOfRooms((prev) => prev + parseInt(hotel?.roomCapacity, 10));
      });
    } else if (!isEmpty(focusOn)) {
      setIsEditable(true);
      setNoOfRooms((prev) => prev + parseInt(focusOn?.roomCapacity, 10));
    }
  }, [focusOn, enableSecondaryHotelGroups]);
  //
  //
  useEffect(() => {
    if (enableSecondaryHotelGroups) {
      setIsEditable(!focusOn?.isPredefined);
    }
  }, [focusOn, enableSecondaryHotelGroups]);
  //
  const handleAddHotelToFavorites = (e, option) => {
    if (hotelGroupList?.some((group) => group?.label === option?.label)) {
      const favoriteHotelSets = hotelGroupList
        ?.map(() => {
          const matchingGroup = selectedHotelGroups?.results?.find(
            ({ hotelGroups }) => hotelGroups && hotelGroups?.hotelGroupName === option?.id
          );
          return matchingGroup
            ? {
                hotelGroups: [
                  {
                    hotelQuery: matchingGroup?.hotelGroups?.hotelQuery,
                    hotelGroupName: matchingGroup?.hotelGroups?.hotelGroupName,
                    isFavorite: !option?.isFavorite,
                  },
                ],
                userId: authUser?.id,
                organizationId,
              }
            : null;
        })
        ?.filter(Boolean)[0];
      dispatch(dashboardActions.addHotelGroupToFavorites(favoriteHotelSets));
    } else {
      dispatch(
        dashboardActions.addHotelToFavorite({
          organizationId,
          userId: authUser?.id,
          hotelId: option?.id,
        })
      );
    }
  };
  //
  const modifyOptions = (options) => {
    const modifiedOptions = [];

    options?.forEach((option) => {
      if (option.isFavorite && !option?.isPredefined) {
        modifiedOptions.push({ ...option, group: 3 }); 
      } else if (!option?.isPredefined) {
        modifiedOptions.push({ ...option, group: 1 });
      }
      if (option.isFavorite && !option?.isPredefined) {
        modifiedOptions.push({ ...option, group: 1 }); 
      }
      if (option?.isPredefined) {
        modifiedOptions.push({ ...option, group: 2 }); 
      }
    });
        // Sort options first by group, then by label
        return modifiedOptions.sort((a, b) => {
          if (a.group === b.group) {
            if (isDemoUser) {
              return Number(a?.label?.split(' ')[1]) - Number(b?.label?.split(' ')[1]);
            }
            return a.label.localeCompare(b.label);
          }
          return b.group - a.group;
        });
  };
  //
  useEffect(() => {
    if (mergedHotelList?.length > 0) {
      const modifiedOptions = modifyOptions(mergedHotelList);
      setModifiedList(modifiedOptions);
    }
  }, [mergedHotelList, isDemoUser]);
  //
  return (
    <CustomStack cssClassName={styles.innerFilter}>
      <FormControl>
        <Grid container justifyContent="space-between" wrap="nowrap">
          <Grid item alignContent="center">
            <FormLabel sx={{ fontSize: 13 }}>VS. Hotel</FormLabel>
          </Grid>
          <Tooltip title="Add/Edit Hotel Set">
            <span>
              <IconButton
                color="primary"
                variant="contained"
                onClick={() => setOpen(true)}
                sx={{
                  '&.Mui-disabled': {
                    background: 'transparent', // 'gray'
                    // color: 'white',
                  },
                  ml: 1,
                }}
              >
                <CustomEditIcon size="15px" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </FormControl>
      <FormControl>
        <Autocomplete
          size="small"
          disabled={isRatePlanEnabled}
          id={`${id}-secondary-hotel-selection`}
          options={modifiedList ?? []}
          getOptionLabel={(option) => (option.label ? option.label : '')}
          groupBy={(option) =>
            // eslint-disable-next-line no-nested-ternary
            option.group === 3 ? 'Favorites' : option.group === 2 ? 'Predefined' : 'NonFavorites'
          }
          renderOption={(props, option) => (
            <Grid key={option?.id} container justifyContent="space-between" direction="row">
              <Grid key={`${option?.id}-set-icons`} item xs={10}>
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.label}
                  {!option.isPredefined &&
                    hotelGroupList?.some((group) => group.label === option?.label) && (
                      <IconButton>
                        <Tooltip title="Hotel Set">
                          <Apartment />
                        </Tooltip>
                      </IconButton>
                    )}
                  {option.isPredefined && (
                    <IconButton>
                      <Tooltip title="Hotel Set">
                        <Apartment />
                      </Tooltip>
                    </IconButton>
                  )}
                </Box>
              </Grid>
              <Grid key={`${option?.id}-icons`} item xs={2}>
                {!option.isPredefined && (
                  <IconButton onClick={(e) => handleAddHotelToFavorites(e, option)}>
                    <Tooltip
                      title={option.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                    >
                      {option.isFavorite ? (
                        <StarIcon sx={{ height: 16, width: 16 }} />
                      ) : (
                        <StarBorderIcon sx={{ height: 16, width: 16 }} />
                      )}
                    </Tooltip>
                  </IconButton>
                )}
              </Grid>
            </Grid>
          )}
          renderGroup={(params) => {
            const sortedGroups = ['Favorites', 'Predefined', 'NonFavorites'].map((groupName) => {
              if (params.group === groupName && params?.children?.length > 0) {
                return (
                  <Grid key={`Groups-${params.group}`}>
                    <Grid key="Favorites">
                      {groupName === 'Favorites' && params?.children?.length > 0 && (
                        <>
                          {params.children}
                          <br />
                          <Divider sx={{ bgcolor: '#61616173' }} variant="middle" />
                          <br />
                        </>
                      )}
                    </Grid>
                    <Grid key="Predefined">
                      {groupName === 'Predefined' && params?.children?.length > 0 && (
                        <>
                          <Typography paddingLeft={2} variant="subtitle2">
                            Pre-Defined Hotel Sets
                          </Typography>
                          {params.children}
                          <br />
                          <Divider sx={{ bgcolor: '#61616173' }} variant="middle" />
                          <br />
                        </>
                      )}
                    </Grid>
                    <Grid key="NonFavorites">
                      {groupName === 'NonFavorites' &&
                        params?.children?.length > 0 &&
                        params.children}
                    </Grid>
                  </Grid>
                );
              }
              return null;
            });
            // Filter out null values and render sorted groups
            return sortedGroups.filter((group) => group !== null);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, value) => {
            handleCompareHotelsChange(e, value);
          }}
          disableClearable
          value={focusOn ?? []}
          renderInput={(params) => (
            <TextField {...params} label={focusOnLabel} size="small" color="primary" />
          )}
          fullWidth
          componentsProps={{
            paper: {
              sx: {
                width: '100%',
                whiteSpace: 'unset',
                wordBreak: 'break-all',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              padding: 0,
              height: 'fit-content',
            },
          }}
        />
      </FormControl>
      <FormControl>
        <Grid
          container
          flexDirection="row"
          justifyContent="space-between"
          wrap="nowrap"
          alignItems="center"
        >
          <CustomToggleSwitch
            label="Normalized - PAR"
            title="Change ‘Total Occupied Rooms’ and ‘Total Revenue’ graphs to display ‘per room’ metrics for the hotels."
            onChange={handleTrendHotelNormalize}
            checked={normalizedViewToggle ?? true}
            disabled={isTrendPercentageChange || disableNormalizedViewToggle}
            id={`${id}-normalized-toggle`}
          />
          <FormLabel sx={{ fontSize: 11, mr: 1 }}>({noOfRooms ?? 0})</FormLabel>
        </Grid>
      </FormControl>
      <AddHotelGroup
        open={open}
        onClose={() => setOpen(false)}
        hotelList={hotelList ?? []}
        selectedHotelGroupName={focusOn ?? []}
        isEditable={isEditable}
      />
    </CustomStack>
  );
};
//
export default CompareHotelsWindow;
