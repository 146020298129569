/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import { REPORT_TYPE } from 'modules/dashboard/constants';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import ExportToCSV from 'modules/dashboard/components/tab-container/export-visual-data/export-csv';
import {
  selectActivePaceTab,
  selectHotelFocusOn,
  selectPaceAlignmentToggle,
  selectPaceCumulativeView,
  selectPaceHotelCumulativeTabularVisuals,
  selectPaceHotelLagAlignedCumulativeTabularVisuals,
  selectPaceHotelLagAlignedTabularVisuals,
  selectPaceHotelTabularFilterList,
  selectPaceHotelTabularVisuals,
  selectPaceLagAligned,
  selectPaceSymmetricComparisonToggle,
  selectRevenueIsDisplayPH,
} from '../../../../selectors';
import { paceActions } from '../../../../slice';
/**
 * Pace Hotel tabular visual report with filters
 */
const PaceHotelTabularWidget = () => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const tabularFilterList = useSelector(selectPaceHotelTabularFilterList);
  const alignmentToggle = useSelector(selectPaceAlignmentToggle);
  const symmetricComparisonToggle = useSelector(selectPaceSymmetricComparisonToggle);
  const tokenDetails = useSelector(selectEmbedToken);
  const paceLagAligned = useSelector(selectPaceLagAligned);
  const paceCumulative = useSelector(selectPaceCumulativeView);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayPH);
  const paceHotelVisuals = useSelector(selectPaceHotelTabularVisuals);
  const paceHotelLagAlignedVisuals = useSelector(selectPaceHotelLagAlignedTabularVisuals);
  const paceHotelAbsoluteCumulativeVisuals = useSelector(selectPaceHotelCumulativeTabularVisuals);
  const paceHotelCumulativeLagAlignedVisuals = useSelector(
    selectPaceHotelLagAlignedCumulativeTabularVisuals
  );
  const activeTab = useSelector(selectActivePaceTab);
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  //
  const [tabularCombined, setTabularCombined] = useState({});
  const [isTabularCombinedLoaded, setIsTabularCombinedLoaded] = useState({});
  //
  const trendFilters = `Alignment : , ${alignmentToggle ? 'On' : 'Off'
    }\r\nSymmetric Comparison : , ${symmetricComparisonToggle ? 'On' : 'Off'
    }\r\nCompared Hotels: , "${hotelFocusOn?.label}"\r\n`;
  // To get visual details
  const getVisual = (index) => {
    let found;
    if (paceCumulative && !paceLagAligned) {
      found = paceHotelAbsoluteCumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (paceLagAligned && !paceCumulative) {
      found = paceHotelLagAlignedVisuals?.find((visual) => visual?.order === index);
    } else if (paceLagAligned && paceCumulative) {
      found = paceHotelCumulativeLagAlignedVisuals?.find((visual) => visual?.order === index);
    } else {
      found = paceHotelVisuals?.find((visual) => visual?.order === index);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => { },
    setIsLoaded = () => { },
    filter = tabularFilterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  return (
    <VisualGrid
      isDisplay={revenueIsDisplay}
      visualName={getVisualName(0)}
      visual={getWidget(0, styles.tabularVisual, setTabularCombined, setIsTabularCombinedLoaded)}
      onClick={() => {
        dispatch(paceActions.setRevenueIsDisplayPH(!revenueIsDisplay));
      }}
      toggleFragment={
        <ExportToCSV
          report={tabularCombined}
          isLoaded={isTabularCombinedLoaded}
          fileName={
            paceCumulative && paceLagAligned
              ? 'PVH_Cumulative_Lag_Aligned'
              : paceCumulative && !paceLagAligned
                ? 'PVH_Cumulative'
                : paceLagAligned && !paceCumulative
                  ? 'PVH_Lag_Aligned'
                  : 'PVH_Daily'
          }
          otherFilters={trendFilters}
        />
      }
      isWorkbookHotelComparison={activeTab === REPORT_TYPE.PACE_HOTEL_WIDGET}
      comparisonHotel={hotelFocusOn}
      displayHotelName
    />
  );
};
//
export default PaceHotelTabularWidget;
