import { sortAndDistinctByField } from './array';
import applyLogicalExpression from './queryExecutor';
import parseLogicalExpression from './queryParser';

/**
 * Handles data filtering based on a logical query expression.
 * @param {string} query - The logical query expression to filter the dataset.
 * @param {Array<Object>} dataset - The dataset to be filtered.
 * @returns {Array<Object>} - The filtered dataset with unique items, sorted by the 'label' property.
 *
 */
const dataHandler = (query, dataset) => {
  const logicalExpression = parseLogicalExpression(query);
  const filteredData = applyLogicalExpression(logicalExpression, dataset);

  // Remove duplicate items and sort by 'label'
  return sortAndDistinctByField(filteredData, 'label');
};
//
export default dataHandler;
