// Commented hidden tab for this release
import { Alert, Grid, IconButton, Portal, Snackbar, Tooltip } from '@mui/material';
import { ENVIRONMENT } from 'config';
import { useState, useEffect, useCallback, useRef } from 'react';
import { selectCurrentUrl } from 'modules/dashboard/selectors';
import { useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { encodedTabs } from 'modules/common/ui-controller';
import { useDispatch, useSelector } from 'react-redux';
import { TAB_VALUE } from 'modules/dashboard/constants';
import { selectPlotUrl } from 'modules/dashboard/components/tab-container/3d-pace-tab/selectors';
import { selectSummaryUrl } from 'modules/dashboard/components/tab-container/summary-tab/selectors';
import { selectTrendTimeUrl } from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
// import { selectPaceTimeWidgetUrl } from 'modules/dashboard/components/tab-container/pace-time-tab/selectors';
// import { selectPaceSegmentWidgetUrl } from 'modules/dashboard/components/tab-container/pace-segment-tab/selectors';
// import { selectTrendSegmentUrl } from 'modules/dashboard/components/tab-container/trend-segment-tab/selectors';
// import { selectTrendHotelsUrl } from 'modules/dashboard/components/tab-container/trend-hotel-tab/selectors';
import { selectWebClickUrl } from 'modules/dashboard/components/tab-container/web-click-tab/selectors';
import { dashboardActions } from 'modules/dashboard/slice';
import { Save, Folder, Share } from 'react-feather';
import SaveUrlFilterDialog from 'modules/dashboard/components/tab-container/share/components/save-url-fliter';
import FilterDropdown from 'modules/dashboard/components/tab-container/share/components/filter-dropdown';
import { selectRegionalMapUrl } from 'modules/dashboard/components/tab-container/regional-map-tab/selectors';
// import { selectIPreferUrl } from 'modules/dashboard/components/tab-container/i-prefer-tab/selectors';
import { selectWorkbookUrl } from '../workbook-report/selectors';
import { selectPaceWidgetShareUrl } from '../pace-tabs/selectors';
// import { selectTrendTabularUrl } from '../trend-tabular-tab/selectors';

/**
 * Share button component
 * @param {*} props
 * @returns
 */
const ShareComponent = ({ tabValue }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  // Obtain url values for each tab. Refer to each selector function for details
  const summaryFilter = useSelector(selectSummaryUrl);
  // const paceTimeWidgetFilter = useSelector(selectPaceTimeWidgetUrl);
  // const paceSegmentWidgetFilter = useSelector(selectPaceSegmentWidgetUrl);
  const regionalMapFilter = useSelector(selectRegionalMapUrl);
  const trendTimeFilter = useSelector(selectTrendTimeUrl);
  // const trendHotelsFilter = useSelector(selectTrendHotelsUrl);
  // const trendSegmentFilter = useSelector(selectTrendSegmentUrl);
  const plotFilter = useSelector(selectPlotUrl);
  const webClickFilter = useSelector(selectWebClickUrl);
  // const IPreferFilter = useSelector(selectIPreferUrl);
  const workbookFilter = useSelector(selectWorkbookUrl);
  const paceFilter = useSelector(selectPaceWidgetShareUrl);
  // const tabularFilter = useSelector(selectTrendTabularUrl);
  const currentUrl = useSelector(selectCurrentUrl);
  //
  const ref = useRef(null);
  //
  const [copyValue, setValue] = useState('');
  const [copied, setCopied] = useState(false);
  const [hash, setHash] = useState('');
  const [openSaveUrlFilterDialog, setOpenSaveUrlFilterDialog] = useState(false);
  const [openLoadFilterDialog, setOpenLoadFilterDialog] = useState(false);

  // Triggered when the value of any of the following changes
  // tabValue,summaryFilter,paceWidgetFilter,regionalMapFilter,trendTimeFilter, trendSegmentFilter, trendHotelsFilter,plotFilter,webClickFilter,
  useEffect(() => {
    // Update url based on the active tab and filter list
    setHash(encodedTabs[tabValue]);
    dispatch(dashboardActions.setTabValue(tabValue));
    switch (tabValue) {
      case TAB_VALUE.SUMMARY:
        dispatch(dashboardActions.setCurrentUrl(summaryFilter));
        break;
      case TAB_VALUE.WORKBOOK_REPORT:
        dispatch(dashboardActions.setCurrentUrl(workbookFilter));
        break;
      // case TAB_VALUE.PACE_TIME_WIDGET:
      //   dispatch(dashboardActions.setCurrentUrl(paceTimeWidgetFilter));
      //   break;
      // case TAB_VALUE.PACE_SEGMENT_WIDGET:
      //   dispatch(dashboardActions.setCurrentUrl(paceSegmentWidgetFilter));
      //   break;
      case TAB_VALUE.REGION_MAP:
        dispatch(dashboardActions.setCurrentUrl(regionalMapFilter));
        break;
      case TAB_VALUE.TREND_TIME:
        dispatch(dashboardActions.setCurrentUrl(trendTimeFilter));
        break;
      // case TAB_VALUE.TREND_SEGMENT:
      //   dispatch(dashboardActions.setCurrentUrl(trendSegmentFilter));
      //   break;
      // case TAB_VALUE.TREND_HOTEL:
      //   dispatch(dashboardActions.setCurrentUrl(trendHotelsFilter));
      //   break;
      case TAB_VALUE.PACE_3D:
        dispatch(dashboardActions.setCurrentUrl(plotFilter));
        break;
      case TAB_VALUE.WEB_CLICK:
        dispatch(dashboardActions.setCurrentUrl(webClickFilter));
        break;
      case TAB_VALUE.PACE:
        dispatch(dashboardActions.setCurrentUrl(paceFilter));
        break;
      case TAB_VALUE.TEST_WORKBOOK_REPORT:
        dispatch(dashboardActions.setCurrentUrl(workbookFilter));
        break;
      // case TAB_VALUE.TREND_TABULAR:
      //   dispatch(dashboardActions.setCurrentUrl(tabularFilter));
      //   break;
      // case TAB_VALUE.I_PREFER:
      //   dispatch(dashboardActions.setCurrentUrl(IPreferFilter));
      //   break;
      default:
        break;
    }
  }, [
    tabValue,
    summaryFilter,
    // paceTimeWidgetFilter,
    // paceSegmentWidgetFilter,
    regionalMapFilter,
    trendTimeFilter,
    // trendSegmentFilter,
    // trendHotelsFilter,
    plotFilter,
    webClickFilter,
    // IPreferFilter,
    workbookFilter,
    paceFilter,
    // tabularFilter,
  ]);
  //
  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);
  //
  const handleClick = () => {
    setCopied(true);
  };
  //
  // Triggered when currentUrl or hash changes
  useEffect(() => {
    // Encrypt url to be shared
    setValue(
      `${ENVIRONMENT.APP_URL}${location?.pathname}?${
        ENVIRONMENT.ENCRYPTION === '1' ? btoa(currentUrl) : currentUrl
      }#${hash}`
    );
  }, [currentUrl, hash]);
  //
  return (
    <Grid container item alignItems="center" justifyContent="center">
      <Portal>
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={copied}
          onClose={() => {
            setCopied(false);
          }}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Copy to clipboard!
          </Alert>
        </Snackbar>
      </Portal>
      <CopyToClipboard onCopy={onCopy} text={copyValue}>
        <IconButton onClick={handleClick}>
          <Tooltip title="Share View">
            <Share size={16} />
          </Tooltip>
        </IconButton>
      </CopyToClipboard>
      <Tooltip title="Save">
        <IconButton onClick={() => setOpenSaveUrlFilterDialog(true)}>
          <Save size={16} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Load">
        <IconButton
          ref={ref}
          onClick={() => {
            setOpenLoadFilterDialog(true);
          }}
        >
          <Folder size={16} />
        </IconButton>
      </Tooltip>
      <SaveUrlFilterDialog
        open={openSaveUrlFilterDialog}
        onClose={() => setOpenSaveUrlFilterDialog(false)}
        url={`${currentUrl}#${hash}`}
      />
      <FilterDropdown
        isOpen={openLoadFilterDialog}
        setOpen={setOpenLoadFilterDialog}
        innerRef={ref}
      />
    </Grid>
  );
};
//
export default ShareComponent;
