import { UiController, breakdownList, events } from 'modules/common/ui-controller';
import { getValuesFromList } from 'modules/common/utils/array';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { paceActions } from '../slice';

/**
 * Custom hook to execute pace segment widget filter command flow in pace tab
 * @param {Boolean} isLoaded
 * @param {Array} chartBreakdownList
 * @param {Array} focusOnList
 */
const usePaceSegmentCommandFlow = (isLoaded, chartBreakdownList, focusOnList) => {
  const dispatch = useDispatch();
  const [breakdownCommandValue, setBreakdownCommandValue] = useState(null);
  const [focusOnCommandValue, setFocusOnCommandValue] = useState(null);

  // Triggers when breakdownCommandValue, chartBreakdownList, isLoaded change
  useEffect(() => {
    if (chartBreakdownList.length > 0 && breakdownCommandValue && isLoaded) {
      // get breakdown option
      const breakdownOption = breakdownList[breakdownCommandValue?.substring(0, 2)];
      // get isDetailed state from command value
      const detailsStatus = parseInt(breakdownCommandValue?.substring(2, 4), 10);
      if (breakdownOption !== undefined) {
        const option = chartBreakdownList?.filter((val) => val?.label?.includes(breakdownOption));
        if (option?.length > 0) {
          dispatch(paceActions.setPaceChartBreakdown(option[0]?.id));
          dispatch(paceActions.setPaceFocusOn([]));
        }
        // set isDetailed  state
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(detailsStatus)) {
          dispatch(paceActions.setIsPaceDetailed(detailsStatus === 1));
          dispatch(paceActions.setPaceFocusOn([]));
        }
      }
      setBreakdownCommandValue(null);
    }
  }, [breakdownCommandValue, chartBreakdownList, isLoaded]);
  // Triggers when focusOnCommandValue, focusOnList, isLoaded values change
  useEffect(() => {
    if (focusOnList.length > 0 && focusOnCommandValue && isLoaded) {
      setTimeout(() => {
        const optionsSelected = getValuesFromList(focusOnCommandValue, focusOnList);
        if (optionsSelected?.length > 0) {
          dispatch(paceActions.setPaceFocusOn(optionsSelected));
        } else {
          dispatch(paceActions.setPaceFocusOn([]));
        }
        setFocusOnCommandValue(null);
      }, 2000);
    }
  }, [focusOnCommandValue, focusOnList?.length, isLoaded]);
  // Listener function to set breakdown command value
  const setPaceBreakdownHandler = (value) => {
    setBreakdownCommandValue(value);
  };
  // Listener function to set focus on value
  const setFocusOnValue = (data) => {
    setFocusOnCommandValue(data);
  };
  // Add Listener function to set breakdown command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_BREAKDOWN, setPaceBreakdownHandler);
    return () => {
      UiController.unsubscribe(events.SET_PACE_BREAKDOWN, setPaceBreakdownHandler);
    };
  }, []);
  // Add event listener function to set focus on command value
  useEffect(() => {
    UiController.subscribe(events.SET_PACE_FOCUS_ON, setFocusOnValue);
    return () => {
      UiController.unsubscribe(events.SET_PACE_FOCUS_ON, setFocusOnValue);
    };
  }, []);
};

export default usePaceSegmentCommandFlow;
