import { VISUAL_HEADER_TYPES } from 'modules/dashboard/constants';

const mapper = {
  PVH_Daily: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    transformColumns: true,
    metrics: {
      Room_Nights: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      Revenue: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      ADR: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
    },
  },
  PVH_Lag_Aligned: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    transformColumns: true,
    metrics: {
      Room_Nights: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      Revenue: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      ADR: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
    },
  },
  PVH_Cumulative: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    transformColumns: true,
    metrics: {
      Room_Nights: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      Revenue: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      ADR: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
    },
  },
  PVH_Cumulative_Lag_Aligned: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    transformColumns: true,
    metrics: {
      Room_Nights: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      Revenue: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      ADR: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
    },
  },
  PVT_Cumulative_Lag_Aligned: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    transformColumns: true,
    metrics: {
      Room_Nights: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      Revenue: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      ADR: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
    },
  },
  PVT_Percentage: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['Selection', 'Comparison', 'Forecast'],
      },
      Revenue: {
        subColumns: ['Selection', 'Comparison', 'Forecast'],
      },
      ADR: {
        subColumns: ['Selection', 'Comparison'],
      },
    },
  },
  PVT_Lag_Aligned: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    transformColumns: true,
    metrics: {
      Room_Nights: {
        subColumns: ['Selection', 'Comparison', 'Forecast'],
      },
      Revenue: {
        subColumns: ['Selection', 'Comparison', 'Forecast'],
      },
      ADR: {
        subColumns: ['Selection', 'Comparison', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['Selection', 'Comparison', '% Chg'],
      },
    },
  },

  PVS_Daily: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE_2,
  },
  PVS_Cumulative: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE_2,
  },
  PVS_Cumulative_Lag_Aligned: {
    groupBy: 'Lags',
    headertype: VISUAL_HEADER_TYPES.DOUBLE_2,
  },

  PVS_Lag_Aligned: {
    groupBy: 'Lags',
    headertype: VISUAL_HEADER_TYPES.DOUBLE_2,
  },
  PVT_Cumulative: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      Revenue: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      ADR: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
    },
  },
  PVT_Daily: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      Revenue: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      ADR: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
    },
  },
  TXS_Cumulative: {
    headertype: VISUAL_HEADER_TYPES.GROUPED_DOUBLE,
    groupBy: 'Date',
  },
  TXS_Weekly: {
    headertype: VISUAL_HEADER_TYPES.GROUPED_DOUBLE,
    groupBy: 'Date Name',
  },
  TXS_Daily: {
    headertype: VISUAL_HEADER_TYPES.GROUPED_DOUBLE,
    groupBy: 'Date',
  },
  TVS_Daily: {
    headertype: VISUAL_HEADER_TYPES.DOUBLE_2,
    groupBy: 'Date',
  },
  TVS_Cumulative: {
    headertype: VISUAL_HEADER_TYPES.DOUBLE_2,
    groupBy: 'Date',
  },
  TVS_Percentage: {
    headertype: VISUAL_HEADER_TYPES.DOUBLE_2,
    groupBy: 'Date',
  },
  TVS_Weekly: {
    headertype: VISUAL_HEADER_TYPES.DOUBLE_2,
    groupBy: 'Date Name',
  },
  TVT_Percentage: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['Selection', 'Comparison'],
      },
      Revenue: {
        subColumns: ['Selection', 'Comparison'],
      },
      ADR: {
        subColumns: ['Selection', 'Comparison'],
      },
    },
  },
  TVT_Cumulative: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      Revenue: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      Cancellation_Count: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
    },
  },
  TVT_Weekly: {
    groupBy: 'Date Name',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      Revenue: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      ADR: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
      ALOS: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
      Lead_Time: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
    },
  },
  TVT_Daily: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      Revenue: {
        subColumns: ['Select.', 'Compar.', '% Chg', 'Forecast'],
      },
      ADR: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
      ALOS: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
      Lead_Time: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['Select.', 'Compar.', '% Chg'],
      },
    },
  },
  TVH_Normalized: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      Revenue: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      ADR: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      ALOS: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      Lead_Time: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
    },
  },
  TVH_Daily: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      Revenue: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      ADR: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      ALOS: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      Lead_Time: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
    },
  },
  TVH_Cumulative: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      Revenue: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      Cancellation_Count: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
    },
  },
  TVH_Weekly: {
    groupBy: 'Date name',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      Revenue: {
        subColumns: ['A Actls', 'B Actls', '% Chg', 'A Fcsts', 'B Fcsts'],
      },
      ADR: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      ALOS: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      Lead_Time: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
      Cancellation_Count: {
        subColumns: ['A Actls', 'B Actls', '% Chg'],
      },
    },
  },
  TVH_Percentage: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['A Actls', 'B Actls'],
      },
      Revenue: {
        subColumns: ['A Actls', 'B Actls'],
      },
      ADR: {
        subColumns: ['A Actls', 'B Actls'],
      },
    },
  },
  CMATT_Daily: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    transformColumns: true,
    metrics: {
      Room_Nights: {
        subColumns: ['metrics_spot_MA', 'Comparison'],
      },
      Revenue: {
        subColumns: ['metrics_spot_MA', 'Comparison'],
      },
      ADR: {
        subColumns: ['metrics_spot_MA', 'Comparison'],
      },
    },
  },
  CMATS_Daily: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE_2,
  },
  CMATH_Daily: {
    groupBy: 'Date',
    headertype: VISUAL_HEADER_TYPES.DOUBLE,
    metrics: {
      Room_Nights: {
        subColumns: ['A Actls', 'B Actls'],
      },
      Revenue: {
        subColumns: ['A Actls', 'B Actls'],
      },
      ADR: {
        subColumns: ['A Actls', 'B Actls'],
      },
    },
  },
};

export default mapper;
