import { useEffect, useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Paper,
  ListSubheader,
} from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { isEmpty } from 'modules/common/helpers/object';
import { useDispatch } from 'react-redux';
import {
  FIELD,
  LABELS,
} from 'modules/dashboard/components/tab-container/inner-filter/components/custom-aggregation/constants';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { generateQuery } from 'modules/dashboard/functions';

/**
 * Transfer list implementation for custom aggregation
 * @param {Array} focusOnList - List of focus on dropdown values
 * @param {Array} group1 - Group A values
 * @param {Array} group2 - Group B values
 * @param {Function} setGroup1 - Function to set group A values
 * @param {Function} setGroup2 - Function to set group B values
 * @param {Boolean} isQueryEnabled - To enable/disable advanced querying feature
 */
const AggregationTransferList = ({
  focusOnList,
  group1,
  group2,
  setGroup1,
  setGroup2,
  isQueryEnabled,
}) => {
  const dispatch = useDispatch();
  //
  const [checked, setChecked] = useState([]);
  const [detailedBreakdown, setDetailedBreakdown] = useState(focusOnList);
  //
  const not = (array1, array2) =>
    array1?.filter((itemA) => !array2?.some((itemB) => itemB?.label === itemA?.label));
  const intersection = (array1, array2) => array1.filter((value) => array2.indexOf(value) !== -1);
  //
  const leftChecked = intersection(checked, group1);
  const rightChecked = intersection(checked, group2);
  const middleChecked = intersection(checked, detailedBreakdown);

  // Handle check box selection of group values
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  // Move all values in middle to group B
  const handleAllRight = () => {
    dispatch(
      trendActions.setSegmentSetBQuery(generateQuery(FIELD, group2.concat(detailedBreakdown)))
    );
    dispatch(trendActions.setSegmentSetAQuery(generateQuery(FIELD, group1)));
    setGroup2(group2.concat(detailedBreakdown));
    setDetailedBreakdown([]);
    dispatch(trendActions.setShowCustomGroupingFilterName(false));
  };

  // Move selected values in middle to group B
  const handleCheckedRight = () => {
    dispatch(trendActions.setSegmentSetBQuery(generateQuery(FIELD, group2.concat(middleChecked))));
    dispatch(trendActions.setSegmentSetAQuery(generateQuery(FIELD, group1)));
    setGroup2(group2.concat(middleChecked));
    setDetailedBreakdown(not(detailedBreakdown, middleChecked));
    setChecked(not(checked, middleChecked));
    dispatch(trendActions.setShowCustomGroupingFilterName(false));
  };

  // Move selected values in middle to group A
  const handleCheckedLeft = () => {
    dispatch(trendActions.setSegmentSetAQuery(generateQuery(FIELD, group1.concat(middleChecked))));
    dispatch(trendActions.setSegmentSetBQuery(generateQuery(FIELD, group2)));
    setGroup1(group1.concat(middleChecked));
    setDetailedBreakdown(not(detailedBreakdown, middleChecked));
    setChecked(not(checked, middleChecked));
    dispatch(trendActions.setShowCustomGroupingFilterName(false));
  };

  // Move all values in middle to group A
  const handleAllLeft = () => {
    dispatch(
      trendActions.setSegmentSetAQuery(generateQuery(FIELD, group1.concat(detailedBreakdown)))
    );
    dispatch(trendActions.setSegmentSetBQuery(generateQuery(FIELD, group2)));
    setGroup1(group1.concat(detailedBreakdown));
    setDetailedBreakdown([]);
    dispatch(trendActions.setShowCustomGroupingFilterName(false));
  };

  // Move all values to middle from group B
  const handleAllMiddleFromRight = () => {
    setDetailedBreakdown(detailedBreakdown.concat(group2));
    dispatch(trendActions.setSegmentSetAQuery(generateQuery(FIELD, group1)));
    dispatch(trendActions.setSegmentSetBQuery(''));
    setGroup2([]);
    dispatch(trendActions.setShowCustomGroupingFilterName(false));
  };

  // Move selected values to middle from group B
  const handleCheckedMiddleFromRight = () => {
    setDetailedBreakdown(detailedBreakdown.concat(rightChecked));
    dispatch(trendActions.setSegmentSetBQuery(generateQuery(FIELD, not(group2, rightChecked))));
    dispatch(trendActions.setSegmentSetAQuery(generateQuery(FIELD, group1)));
    setGroup2(not(group2, rightChecked));
    setChecked(not(checked, rightChecked));
    dispatch(trendActions.setShowCustomGroupingFilterName(false));
  };

  // Move selected values to middle from group A
  const handleCheckedMiddleFromLeft = () => {
    dispatch(trendActions.setSegmentSetAQuery(generateQuery(FIELD, not(group1, leftChecked))));
    dispatch(trendActions.setSegmentSetBQuery(generateQuery(FIELD, group2)));
    setDetailedBreakdown(detailedBreakdown.concat(leftChecked));
    setGroup1(not(group1, leftChecked));
    setChecked(not(checked, leftChecked));
    dispatch(trendActions.setShowCustomGroupingFilterName(false));
  };

  // Move all values to middle from group A
  const handleAllMiddleFromLeft = () => {
    dispatch(trendActions.setSegmentSetAQuery(''));
    dispatch(trendActions.setSegmentSetBQuery(generateQuery(FIELD, group2)));
    setDetailedBreakdown(detailedBreakdown.concat(group1));
    setGroup1([]);
    dispatch(trendActions.setShowCustomGroupingFilterName(false));
  };

  // Triggered when focusOnList,group1 or group2 value change
  useEffect(() => {
    // set middle values based on the change in group A, B and breakdown sub categories
    if (isEmpty(group1) && isEmpty(group2)) {
      setDetailedBreakdown(focusOnList);
    }
    if (group2.length && group1.length) {
      setDetailedBreakdown(not(focusOnList, [...group2, ...group1]));
    } else if (!isEmpty(group1)) {
      setDetailedBreakdown(not(focusOnList, group1));
    } else if (!isEmpty(group2)) {
      setDetailedBreakdown(not(focusOnList, group2));
    }
  }, [focusOnList, group2, group1]);
  //
  const customList = (items, header) => {
    const listItems = [...items];
    const sortArray =
      !isEmpty(listItems) &&
      listItems?.sort((element1, element2) => element1.label.localeCompare(element2.label));
    //
    return (
      <Paper
        sx={{ maxWidth: 190, minWidth: 190, height: 350, overflow: 'auto' }}
        variant="outlined"
      >
        <List
          dense
          component="div"
          role="list"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {header}
            </ListSubheader>
          }
        >
          {!isEmpty(sortArray) &&
            sortArray?.map((value) => (
              <ListItem
                key={value.id}
                role="listitem"
                onClick={handleToggle(value)}
                sx={{ padding: 0 }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': value?.id,
                    }}
                    disabled={isQueryEnabled}
                  />
                </ListItemIcon>
                <ListItemText id={value?.id} primary={value?.label} />
              </ListItem>
            ))}
        </List>
      </Paper>
    );
  };
  //
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" wrap="nowrap">
      <Grid item>{customList(group1, LABELS.GROUP_A)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={detailedBreakdown.length === 0 || isQueryEnabled}
            aria-label="move all left"
          >
            <KeyboardDoubleArrowLeftIcon fontSize="small" />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={middleChecked.length === 0 || isQueryEnabled}
            aria-label="move selected left"
          >
            <KeyboardArrowLeftIcon fontSize="small" />
          </Button>
          <br />
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedMiddleFromLeft}
            disabled={leftChecked.length === 0 || isQueryEnabled}
            aria-label="move selected left"
          >
            <KeyboardArrowRightIcon fontSize="small" />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllMiddleFromLeft}
            disabled={group1.length === 0 || isQueryEnabled}
            aria-label="move all left"
          >
            <KeyboardDoubleArrowRightIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(detailedBreakdown, 'Breakdown')}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={detailedBreakdown.length === 0 || isQueryEnabled}
            aria-label="move all right"
          >
            <KeyboardDoubleArrowRightIcon fontSize="small" />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={middleChecked.length === 0 || isQueryEnabled}
            aria-label="move selected right"
          >
            <KeyboardArrowRightIcon fontSize="small" />
          </Button>
          <br />
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedMiddleFromRight}
            disabled={rightChecked.length === 0 || isQueryEnabled}
            aria-label="move selected left"
          >
            <KeyboardArrowLeftIcon fontSize="small" />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllMiddleFromRight}
            disabled={group2.length === 0 || isQueryEnabled}
            aria-label="move all left"
          >
            <KeyboardDoubleArrowLeftIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(group2, LABELS.GROUP_B)}</Grid>
    </Grid>
  );
};
//
export default AggregationTransferList;
