import { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TAB_VALUE } from 'modules/dashboard/constants';
import { ENVIRONMENT } from 'config';
import { differenceInDays } from 'date-fns';
import {
  selectSummaryFilterList,
  selectPaceVisualFilterList,
  selectSummaryVisualToggle,
  selectPaceVisualIsDisplay,
  selectRevenueSetIsDisplay,
  selectCxlSetIsDisplay,
  selectSummaryVisual,
  selectSummaryHotelVisual,
  // selectHotelGroupFocusOn,
  selectHotelFocusOn,
  selectEnableSecondaryHotelGroups,
  selectSummaryHotelComparison,
} from 'modules/dashboard/components/tab-container/summary-tab/selectors';
import { CustomToggleSwitch } from 'modules/common/components';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import summaryStyles from 'modules/dashboard/components/tab-container/summary-tab/summary.module.scss';
import { summaryActions } from 'modules/dashboard/components/tab-container/summary-tab/slice';
import SummaryFilter from 'modules/dashboard/components/tab-container/summary-tab/components/filter-panel';
import {
  selectEnableHotelGroups,
  selectHotelName,
  selectSummaryForecastFilter,
  selectTargetDate,
} from 'modules/dashboard/selectors';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { DateTime } from 'luxon';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import useSummaryWidget from 'modules/dashboard/components/tab-container/summary-tab/hooks/use-summary-widget';
import { isEmpty } from '../../../../../../common/helpers/object';

/**
 * Summary widget report with filters
 * @param {Number} value - Summary widget tab value
 * @param {String} accessToken - Power BI access token
 * @param {Boolean} active - whether summary tab is active
 * @param {Boolean} toggleDisabled - PMS sync toggle disabled state
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 * @returns
 */
const SummaryWidget = ({
  value,
  accessToken,
  active,
  latestDate,
  reportType,
  hotelTimezone,
  toggleDisabled,
}) => {
  useSummaryWidget();
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const summaryForecastFilter = useSelector(selectSummaryForecastFilter);
  const summaryFilterList = useSelector(selectSummaryFilterList);
  const paceVisualFilterList = useSelector(selectPaceVisualFilterList);
  const visualToggle = useSelector(selectSummaryVisualToggle);
  const targetDate = useSelector(selectTargetDate);
  const paceVisualIsDisplay = useSelector(selectPaceVisualIsDisplay);
  const revenueSetIsDisplay = useSelector(selectRevenueSetIsDisplay);
  const cxlSetIsDisplay = useSelector(selectCxlSetIsDisplay);
  const selectedHotel = useSelector(selectHotelName);
  const visuals = useSelector(selectSummaryVisual);
  const hotelVisuals = useSelector(selectSummaryHotelVisual);
  // const hotelGroupFocusOn = useSelector(selectHotelGroupFocusOn);
  const hotelFocusOn = useSelector(selectHotelFocusOn);
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const isSummaryHotelComparison = useSelector(selectSummaryHotelComparison);
  //
  const [isVisualCard1Loaded, setIsVisualCard1Loaded] = useState(false);
  const [isVisualCard2Loaded, setIsVisualCard2Loaded] = useState(false);
  const [isPaceVisualLoaded, setIsPaceVisualLoaded] = useState(false);
  const [visualToggleDisabled, setVisualToggleDisabled] = useState(false);
  const [isVisualLoading, setIsVisualLoading] = useState(false);
  //
  /* Hide overlay till Henry's feedback */
  // const [isVisualCard1Rendered, setIsVisualCard1Rendered] = useState(false);
  // const [isVisualCard2Rendered, setIsVisualCard2Rendered] = useState(false);
  //
  useEffect(() => {
    // To enable hotel comparison view based on selected hotels
    if (hotelFocusOn?.id !== selectedHotel?.id) {
      dispatch(summaryActions.setIsSummaryHotelComparison(true));
    } else if (enableSecondaryHotelGroups && !enableHotelGroups) {
      dispatch(summaryActions.setIsSummaryHotelComparison(true));
    } else if (!enableSecondaryHotelGroups && enableHotelGroups) {
      dispatch(summaryActions.setIsSummaryHotelComparison(true));
    } else {
      dispatch(summaryActions.setIsSummaryHotelComparison(false));
    }
  }, [
    enableHotelGroups,
    enableSecondaryHotelGroups,
    // hotelGroupFocusOn,
    selectedHotel,
    hotelFocusOn,
  ]);
  // To get visual details based on index
  const getVisual = (index) => {
    const found = isSummaryHotelComparison
      ? hotelVisuals?.find((visual) => visual?.order === index)
      : visuals?.find((visual) => visual?.order === index);
    return found;
  };
  // To get visual name based on index
  const getVisualName = (index) => getVisual(index)?.name;

  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = summaryFilterList,
    setIsRendered = () => {}
  ) => {
    const found = getVisual(index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => setPBIReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
          setIsRendered={(r) => setIsRendered(r)}
        />
      )
    );
  };
  // Triggered when targetDate value changes
  useEffect(() => {
    // Manage state to disable/enable visual toggle
    const lastBookingDateUtc = latestDate ? DateTime.fromSQL(latestDate, { zone: 'utc' }) : '';
    const formattedLastBookingDate = latestDate
      ? lastBookingDateUtc.setZone(hotelTimezone).toFormat('MM-dd-yyyy')
      : '';
    if (
      ENVIRONMENT.ALLOW_EXTENDED_FORECASTS?.toLowerCase() === 'true' &&
      differenceInDays(new Date(targetDate.startDate), new Date(targetDate.endDate)) === 0 &&
      differenceInDays(new Date(targetDate.endDate), new Date(formattedLastBookingDate)) >= 28
    ) {
      setVisualToggleDisabled(!summaryForecastFilter ?? false);
    } else {
      dispatch(summaryActions.setSummaryVisualToggle(false));
      setVisualToggleDisabled(true);
    }
  }, [targetDate, summaryForecastFilter, latestDate]);

  // set common visual loading status based on visual collapse
  useEffect(() => {
    if (!revenueSetIsDisplay && cxlSetIsDisplay && paceVisualIsDisplay) {
      if (isVisualCard2Loaded && isPaceVisualLoaded) {
        setIsVisualLoading(true);
      } else {
        setIsVisualLoading(false);
      }
    } else if (!cxlSetIsDisplay && paceVisualIsDisplay && revenueSetIsDisplay) {
      if (isVisualCard1Loaded && isPaceVisualLoaded) {
        setIsVisualLoading(true);
      } else {
        setIsVisualLoading(false);
      }
    } else if (!paceVisualIsDisplay && revenueSetIsDisplay && cxlSetIsDisplay) {
      if (isVisualCard1Loaded && isVisualCard2Loaded) {
        setIsVisualLoading(true);
      } else {
        setIsVisualLoading(false);
      }
    } else if (!paceVisualIsDisplay && !revenueSetIsDisplay && cxlSetIsDisplay) {
      if (isVisualCard2Loaded) {
        setIsVisualLoading(true);
      } else {
        setIsVisualLoading(false);
      }
    } else if (!paceVisualIsDisplay && revenueSetIsDisplay && !cxlSetIsDisplay) {
      if (isVisualCard1Loaded) {
        setIsVisualLoading(true);
      } else {
        setIsVisualLoading(false);
      }
    } else if (paceVisualIsDisplay && !revenueSetIsDisplay && !cxlSetIsDisplay) {
      if (isPaceVisualLoaded) {
        setIsVisualLoading(true);
      } else {
        setIsVisualLoading(false);
      }
    } else if (paceVisualIsDisplay && revenueSetIsDisplay && cxlSetIsDisplay) {
      if (isVisualCard1Loaded && isPaceVisualLoaded && isVisualCard2Loaded) {
        setIsVisualLoading(true);
      } else {
        setIsVisualLoading(false);
      }
    } else if (!paceVisualIsDisplay && !revenueSetIsDisplay && !cxlSetIsDisplay) {
      setIsVisualLoading(true);
    }
  }, [
    paceVisualIsDisplay,
    revenueSetIsDisplay,
    cxlSetIsDisplay,
    isVisualCard1Loaded,
    isVisualCard2Loaded,
    isPaceVisualLoaded,
  ]);
  //
  useEffect(() => {
    dispatch(summaryActions.setSummaryVisualToggle(false));
  }, [summaryForecastFilter]);
  //
  return (
    active && (
      <Grid
        container
        direction="row"
        className={styles.mainGrid}
        sx={{
          display: value === TAB_VALUE.SUMMARY ? '' : 'none',
        }}
      >
        <Grid
          item
          xs={2}
          cc={2.5}
          md={2}
          sx={{
            ml: {
              xs: 0,
              cc: 0.25,
              md: 0,
            },
          }}
        >
          <SummaryFilter
            reportType={reportType}
            visual={getWidget(100, styles.symmetricComparisonWindow)}
            visualName={getVisualName(100)}
            tabValue={value}
            toggleDisabled={toggleDisabled}
            latestDate={latestDate}
            hotelTimezone={hotelTimezone}
            isLoaded={isVisualLoading}
          />
        </Grid>
        {!isEmpty(summaryFilterList) && !isEmpty(paceVisualFilterList) && (
          <Grid item xs={10} cc={9.25} md={10}>
            <Box sx={{ flexGrow: 1, position: 'relative' }}>
              {/* Hide overlay till Henry's feedback */}
              {/* {!isVisualCard1Rendered && revenueSetIsDisplay && (
              <Grid container justifyContent="center">
                <Grid className={summaryStyles.EmptyVisualCard} />
              </Grid>
            )} */}
              <VisualGrid
                isDisplay={revenueSetIsDisplay}
                visualName={getVisualName(0)}
                visual={getWidget(
                  0,
                  summaryStyles.VisualCard,
                  () => {},
                  setIsVisualCard1Loaded,
                  summaryFilterList
                  // setIsVisualCard1Rendered
                )}
                onClick={() => {
                  dispatch(summaryActions.setRevenueSetIsDisplay(!revenueSetIsDisplay));
                }}
                // isRendered={isVisualCard1Rendered}
              />
              {/* Hide overlay till Henry's feedback */}
              {/* {!isVisualCard2Rendered && cxlSetIsDisplay && (
              <Grid container justifyContent="center">
                <Grid className={summaryStyles.EmptyVisualCardWhite}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    style={{ height: '100%' }}
                  >
                    <Grid item>
                      <CircularProgress
                        size={50}
                        sx={{
                          color: grey[500],
                        }}
                      />
                    </Grid>
                    <Grid item sx={{ color: grey[700], mt: 4 }}>
                      <Typography variant="h4"> Loading data...</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )} */}

              <VisualGrid
                isDisplay={cxlSetIsDisplay}
                visualName={getVisualName(1)}
                visual={getWidget(
                  1,
                  summaryStyles.VisualCard,
                  () => {},
                  setIsVisualCard2Loaded,
                  summaryFilterList
                  // setIsVisualCard2Rendered
                )}
                onClick={() => {
                  dispatch(summaryActions.setCxlSetIsDisplay(!cxlSetIsDisplay));
                }}
              />
              <VisualGrid
                isDisplay={paceVisualIsDisplay}
                visualName={getVisualName(visualToggle ? 4 : 2)}
                visual={
                  <>
                    {visualToggle &&
                      getWidget(
                        4,
                        styles.paceVisual,
                        () => {},
                        () => {},
                        paceVisualFilterList
                      )}
                    {!visualToggle &&
                      getWidget(
                        2,
                        styles.paceVisual,
                        () => {},
                        setIsPaceVisualLoaded,
                        paceVisualFilterList
                      )}
                  </>
                }
                onClick={() => {
                  dispatch(summaryActions.setPaceVisualIsDisplay(!paceVisualIsDisplay));
                }}
                information="Displays a 7-day forecast for Stay Dates up to 84 days ahead. Only valid for single stay date selections."
                toggleFragment={
                  <CustomToggleSwitch
                    label="Extended Forecasts"
                    size="small"
                    labelPlacement="start"
                    onChange={(event) =>
                      dispatch(summaryActions.setSummaryVisualToggle(event.target.checked))
                    }
                    checked={visualToggle ?? false}
                    disabled={visualToggleDisabled}
                  />
                }
              />
            </Box>
          </Grid>
        )}
      </Grid>
    )
  );
};
//
export default SummaryWidget;
