import { useRef, useState } from 'react';
import {
  Button,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonGroup,
  ClickAwayListener,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
/**
 * Implementation of split button with dropdown options
 * @param {Array} options - Options for dropdown menu
 * @param {Function} handleClick - Function to trigger when the button is clicked
 * @param {String} color - Button background color
 * @param {String} type - Button action type
 * @returns
 */
const SplitButton = ({
  options,
  handleClick,
  color = 'primary',
  type = 'submit',
  disabled = false,
  tabName = 'tab',
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  //
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  //
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  //
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  //
  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" color={color}>
        <Button
          size="small"
          onClick={() => handleClick(selectedIndex)}
          sx={{ width: 110, textTransform: 'uppercase' }}
          type={type}
          disabled={disabled}
          id={`auto-${tabName}-save-${selectedIndex}`}
        >
          {options[selectedIndex]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={disabled}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
//
export default SplitButton;
