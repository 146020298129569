import { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import TabularSwitch from 'modules/common/components/tabular-switch';
import {
  selectRegionMapFilterList,
  selectRegionalBreakdownTabularView,
} from 'modules/dashboard/components/tab-container/regional-map-tab/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import regionStyles from 'modules/dashboard/components/tab-container/regional-map-tab/regional-map.module.scss';
import { TAB_VALUE } from 'modules/dashboard/constants';
import ExportData from 'modules/dashboard/components/tab-container/regional-map-tab/components/widget-pane/export-data';
import RegionalMapFilter from 'modules/dashboard/components/tab-container/regional-map-tab/components/filter-panel';
import { regionalMapActions } from 'modules/dashboard/components/tab-container/regional-map-tab/slice';
import useRegionMap from '../../hooks/use-region-map';
import VisualCard from '../../../visual-card';

const gridItemStyle = {
  backgroundColor: 'white',
  padding: '0px',
  margin: '0px',
  height: '7%',
};
/**
 * Regional map report with filters
 * @param {Number} value - Regional map tab value
 * @param {String} accessToken - Power BI access token
 * @param {Object} visuals - Regional map tab visual details
 * @param {Boolean} active - whether Regional map tab is active
 * @param {Boolean} toggleDisabled - PMS sync toggle disabled state
 * @param {Date} latestDate - Latest booking date of selected hotel
 * @param {String} hotelTimezone - Timezone of selected hotel
 * @param {String} reportType - Report type of active tab
 * @returns
 */

const RegionalMap = ({
  value,
  accessToken,
  visuals,
  active,
  latestDate,
  reportType,
  hotelTimezone,
  toggleDisabled,
}) => {
  useRegionMap();
  const dispatch = useDispatch();
  const [report, setReport] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  //
  const filterList = useSelector(selectRegionMapFilterList);
  const regionalBreakdownTabularView = useSelector(selectRegionalBreakdownTabularView);
  // To get visuals based on the configuration
  const getWidget = (index, cssClassName) => {
    const found = visuals?.find((visual) => visual?.order === index);
    //
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filterList}
          setPBIReport={(r) => setReport(r)}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };

  // Triggered when tab value or active tab change
  useEffect(() => {
    // Scroll up when the regional map tab is clicked
    if (value === TAB_VALUE.REGION_MAP) window.scrollTo(0, 0);
  }, [value, active]);
  //
  return (
    active && (
      <Grid
        container
        direction="row"
        className={styles.mainGrid}
        sx={{
          display: value === TAB_VALUE.REGION_MAP ? '' : 'none',
        }}
      >
        <Grid
          item
          xs={2}
          cc={2.5}
          md={2}
          sx={{
            ml: {
              xs: 0,
              cc: 0.25,
              md: 0,
            },
          }}
        >
          <RegionalMapFilter
            reportType={reportType}
            tabValue={value}
            latestDate={latestDate}
            hotelTimezone={hotelTimezone}
            toggleDisabled={toggleDisabled}
            isLoaded={isLoaded}
          />
        </Grid>
        <Grid item xs={10} cc={9.25} md={10}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={styles.mainGrid}
            >
              <Grid mb={2}>
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={gridItemStyle}
                >
                  <TabularSwitch
                    dispatch={dispatch}
                    status={regionalBreakdownTabularView}
                    onChangeEvent={regionalMapActions.setRegionalBreakdownTabularView}
                    gridSx={{ paddingBottom: 1 }}
                  />
                </Grid>
                {getWidget(0, regionStyles.regionMapBreakdown)}
              </Grid>
              {regionalBreakdownTabularView && (
                <Grid mb={2}>
                  <Grid
                    container
                    item
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={gridItemStyle}
                  >
                    {isLoaded && <ExportData regionalMapReport={report} isLoaded={isLoaded} />}
                  </Grid>
                  {getWidget(
                    1,
                    regionStyles.regionMapBreakdownTabularView,
                    () => {},
                    () => {}
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    )
  );
};
//
export default RegionalMap;
