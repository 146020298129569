import { Grid } from '@mui/material';
import { Formik } from 'formik';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'modules/common/helpers/object';
import ERROR_TYPES from 'modules/common/constants/error-types';
import ActionButton from 'modules/common/components/action-button';
import { Alert, TextField } from 'modules/common/components/add-hotel-group/style';
import queryBoxValidation from 'modules/common/components/add-hotel-group/validation/query-validation';
import { LABELS } from 'modules/common/components/add-hotel-group/constants';
import { dashboardActions } from 'modules/dashboard/slice';
import { selectHotelGroupQuery } from 'modules/dashboard/selectors';
import dataHandler from 'modules/common/utils/dataHandler';

/** Implementation to categorize Group A and Group B based on user input query
 * @param {Array} breakdownSubCategories - List of breakdown sub categories
 * @param {Function} setGroup1 - Function to set Group A values
 * @param {Function} setGroup2 - Function to set Group B values
 * @param {Function} onClose - Function to trigger on close event
 * @param {Boolean} open - To open/ close dialog box
 * @param {Boolean} isQueryEnabled - To enable/disable advanced querying feature
 * @param {Boolean} loadFilter - To load saved filter
 * @param {Function} setLoadFilter  - Function to set load filter true when the saved filter is clicked to be loaded
 * @returns
 */

const QueryBox = ({
  setGroupList,
  open,
  isQueryEnabled,
  loadFilter,
  setLoadFilter,
  focusOnList,
  setTemGroupList,
}) => {
  const dispatch = useDispatch();

  // setA Query and set B Query values are selected from store here. Refer each selector function for details
  const hotelGroupQuery = useSelector(selectHotelGroupQuery);
  //
  const initialValues = {
    setHotelGroup: '',
  };
  const [hotelGroupNotification, setHotelGroupNotification] = useState({ type: '', message: '' });

  // To preview values for Group A and B based on the input query
  const previewValues = (setAQuery = hotelGroupQuery) => {
    let setGroup = [];
    setGroupList([]);
    //
    if (setAQuery) {
      setGroup = dataHandler(setAQuery, focusOnList);
      if (!isEmpty(setGroup)) {
        setGroupList(setGroup);
        setTemGroupList(setGroup);
        setHotelGroupNotification({ type: '', message: '' });
      } else {
        setHotelGroupNotification({ type: 'error', message: 'No match found for Hotel Group' });
      }
    }
    setLoadFilter(false);
  };

  // Triggered when load filter value changes
  useEffect(() => {
    // Call preview values function if loadFilter is  true to display selected filter values in transfer list
    if (loadFilter) previewValues();
  }, [loadFilter]);
  //
  return (
    open && (
      <Formik
        initialValues={initialValues}
        validationSchema={queryBoxValidation}
        onSubmit={() => previewValues()}
        enableReinitialize
      >
        {({ errors, handleBlur, handleSubmit, setFieldValue, touched, resetForm }) => (
          <form noValidate onSubmit={handleSubmit}>
            {hotelGroupNotification?.type === ERROR_TYPES.ERROR &&
              hotelGroupNotification?.message && (
                <Alert mt={2} mb={3} severity={hotelGroupNotification?.type}>
                  {hotelGroupNotification?.message}
                </Alert>
              )}
            <TextField
              multiline
              name="setHotelGroup"
              value={hotelGroupQuery}
              error={Boolean(touched.setHotelGroup && errors.setHotelGroup)}
              helperText={touched.setHotelGroup && errors.setHotelGroup}
              fullWidth
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue('setHotelGroup', event.target.value);
                dispatch(dashboardActions.setHotelGroupQuery(event.target.value));
              }}
              my={2}
              label={LABELS.GROUP_HOTEL}
              size="small"
              disabled={!isQueryEnabled}
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: 2,
                  height: 'fit-content',
                },
              }}
            />
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item mx={3}>
                <ActionButton
                  type="reset"
                  label="Clear"
                  onClick={() => {
                    resetForm();
                    dispatch(dashboardActions.setHotelGroupQuery(''));
                  }}
                  disabled={!isQueryEnabled}
                />
              </Grid>
              <Grid item>
                <ActionButton type="submit" label="Preview" disabled={!isQueryEnabled} />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    )
  );
};
//
export default QueryBox;
