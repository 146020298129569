import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectPmsSync,
  selectTargetDate,
  selectPaceHorizon,
  selectHotelName,
  selectEnableHotelGroups,
  selectReportType,
} from 'modules/dashboard/selectors';
import { PACE_HORIZON_SELECTION, REPORT_TYPE, TAGS } from 'modules/dashboard/constants';
import {
  getBookingDateSchema,
  getHotelIdSchema,
  getPaceWidgetPaceHorizonSchema,
  getPmsSyncSchema,
  getSegmentFocusOnSchema,
  getTargetDateSchema,
} from 'modules/dashboard/components/tab-container/inner-filter/functions';
import {
  selectPaceBookingPaceWindow,
  selectPaceBookingDate,
  selectPaceCancellationFilterList,
  selectPaceFocusOn,
  selectPaceChartBreakdown,
  selectIsPaceDetailed,
} from 'modules/dashboard/components/tab-container/pace-tabs/selectors';
import { paceActions } from 'modules/dashboard/components/tab-container/pace-tabs/slice';
import writeLog from 'modules/common/utils/filter-log';
import { storeVisualMapper } from 'modules/common/utils/filter-visual-mapper';
import VISUAL_MAPPER from 'modules/common/utils/visual-mapper';
import { getSelectedBreakdown } from '../../../../functions';

/**
 * Custom hook to generate pace widget cancellation filter schemas
 */
const usePaceCancellationWidget = () => {
  const dispatch = useDispatch();
  //
  const targetDate = useSelector(selectTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const paceHorizon = useSelector(selectPaceHorizon);
  const bookingDate = useSelector(selectPaceBookingDate);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const reportType = useSelector(selectReportType);
  const cancellationFilterList = useSelector(selectPaceCancellationFilterList);
  const bookingPaceWindow = useSelector(selectPaceBookingPaceWindow);
  const focusOn = useSelector(selectPaceFocusOn);
  const chartBreakdown = useSelector(selectPaceChartBreakdown);
  const isDetailed = useSelector(selectIsPaceDetailed);
  //
  useEffect(() => {
    if (reportType !== REPORT_TYPE.PACE || !hotelId || !visualFilters) return;
    const targetDateFilter = getTargetDateSchema(
      visualFilters,
      targetDate?.startDate,
      targetDate?.endDate
    );
    //
    const bookingDateFilter = getBookingDateSchema(
      visualFilters,
      bookingDate?.startDate,
      bookingDate?.endDate
    );
    let hotelIdFilter = [];
    if (enableHotelGroups) {
      const hotelListIds = hotelId?.hotels?.map((hotel) => hotel?.databricksId);
      hotelIdFilter = getHotelIdSchema(hotelListIds, visualFilters);
    } else {
      hotelIdFilter = getHotelIdSchema(hotelId?.databricksId, visualFilters);
    }
    const paceHorizonFilter = getPaceWidgetPaceHorizonSchema(
      visualFilters,
      paceHorizon,
      bookingPaceWindow === PACE_HORIZON_SELECTION.BOOKING_PACE_HORIZON
    );
    const pmsSyncFilter = getPmsSyncSchema(visualFilters, pmsSyncToggle, TAGS.PACE_WIDGET);
    const selectedBreakdown = getSelectedBreakdown(visualFilters, chartBreakdown, isDetailed);
    const segmentFocusOnFilter = getSegmentFocusOnSchema(
      selectedBreakdown,
      focusOn?.map((item) => item?.label)
    );
    //
    if (
      targetDateFilter &&
      hotelIdFilter &&
      paceHorizonFilter &&
      pmsSyncFilter &&
      bookingDateFilter
    ) {
      let filters = [
        targetDateFilter,
        hotelIdFilter,
        paceHorizonFilter,
        ...pmsSyncFilter,
        bookingDateFilter,
      ];
      if (focusOn?.length) {
        filters = [...filters, segmentFocusOnFilter];
      }
      //
      if (JSON.stringify(cancellationFilterList) !== JSON.stringify(filters)) {
        storeVisualMapper(VISUAL_MAPPER.paceCancellationFilter);
        //
        writeLog('Pace Cancellation Count Filters', filters);
        dispatch(paceActions.setPaceCancellationFilterList(filters));
      }
    }
  }, [
    pmsSyncToggle,
    visualFilters,
    targetDate,
    hotelId,
    paceHorizon,
    enableHotelGroups,
    reportType,
    bookingDate,
    bookingPaceWindow,
    focusOn,
    chartBreakdown,
    isDetailed,
  ]);
};
//
export default usePaceCancellationWidget;
