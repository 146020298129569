/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsCumulative,
  selectTrendTimeWeeklyView,
  selectTrendSymmetricComparisonToggle,
  selectTrendTimeAlignmentToggle,
  selectRevenueIsDisplayTT,
  selectTrendTimeTabularVisuals,
  selectTrendTimeCumulativeTabularVisuals,
  selectTrendTimeWeeklyTabularVisuals,
  selectIsPercentageChange,
  selectTrendTimePercentageTabularVisuals,
  selectTrendHotelNormalizedView,
  selectTrendTimeTabularFilterList,
  selectTrendHotelFocusOn,
  selectActiveTrendTab,
  selectIsMovingAverage,
  selectMovingAverageTabularVisualTT,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import styles from 'modules/dashboard/components/tab-container/tab-container.module.scss';
import { selectEmbedToken } from 'modules/dashboard/selectors';
import VisualGrid from 'modules/dashboard/components/tab-container/visual-grid';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import VisualCard from 'modules/dashboard/components/tab-container/visual-card';
import ExportToCSV from 'modules/dashboard/components/tab-container/export-visual-data/export-csv';
import { REPORT_TYPE } from 'modules/dashboard/constants';
/**
 * Trend Time tabular visual report with filters
 */
const TrendTimeTabularWidget = () => {
  const dispatch = useDispatch();
  // States are obtained from redux store. Refer each selector function for details.
  const tabularFilterList = useSelector(selectTrendTimeTabularFilterList);
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const isCumulative = useSelector(selectIsCumulative);
  const alignmentToggle = useSelector(selectTrendTimeAlignmentToggle);
  const symmetricComparisonToggle = useSelector(selectTrendSymmetricComparisonToggle);
  const tokenDetails = useSelector(selectEmbedToken);
  const visuals = useSelector(selectTrendTimeTabularVisuals);
  const weeklyVisuals = useSelector(selectTrendTimeWeeklyTabularVisuals);
  const cumulativeVisuals = useSelector(selectTrendTimeCumulativeTabularVisuals);
  const revenueIsDisplay = useSelector(selectRevenueIsDisplayTT);
  const percentageVisuals = useSelector(selectTrendTimePercentageTabularVisuals);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  const hotelFocusOn = useSelector(selectTrendHotelFocusOn);
  const activeTab = useSelector(selectActiveTrendTab);
  const isMovingAverage = useSelector(selectIsMovingAverage);
  const movingAverageTabularVisuals = useSelector(selectMovingAverageTabularVisualTT);
  //
  const [tabularCombined, setTabularCombined] = useState({});
  const [isTabularCombinedLoaded, setIsTabularCombinedLoaded] = useState({});
  //
  const trendFilters = `Alignment : , ${
    alignmentToggle ? 'On' : 'Off'
  }\r\nSymmetric Comparison : , ${symmetricComparisonToggle ? 'On' : 'Off'}\r\n`;
  // To get visual details
  const getVisual = (index) => {
    let found;
    if (isCumulative) {
      found = cumulativeVisuals?.find((visual) => visual?.order === index);
    } else if (weeklyViewToggle) {
      found = weeklyVisuals?.find((visual) => visual?.order === index);
    } else if (isPercentageChange && !weeklyViewToggle && !isCumulative && !normalizedViewToggle) {
      found = percentageVisuals?.find((visual) => visual?.order === index);
    } else if (isMovingAverage && !weeklyViewToggle && !isPercentageChange) {
      found = movingAverageTabularVisuals?.find((visual) => visual?.order === index);
    } else {
      found = visuals?.find((visual) => visual?.order === index);
    }
    return found;
  };
  // To get visuals based on the configuration
  const getWidget = (
    index,
    cssClassName,
    setPBIReport = () => {},
    setIsLoaded = () => {},
    filter = tabularFilterList
  ) => {
    const found = getVisual(index);
    return (
      found && (
        <VisualCard
          cssClassName={cssClassName}
          accessToken={tokenDetails?.accessToken}
          embedUrl={found?.embedUrl}
          id={found?.reportId}
          visualName={found?.name}
          pageName={found?.pageName}
          pageDisplayName={found?.pageDisplayName}
          reportName={found?.reportName}
          filters={filter}
          setPBIReport={(r) => {
            setPBIReport(r);
          }}
          setIsLoaded={(d) => setIsLoaded(d)}
        />
      )
    );
  };
  // To get visual names based on visual order
  const getVisualName = (order) => getVisual(order)?.name;
  //
  return (
    <>
      <VisualGrid
        isDisplay={revenueIsDisplay}
        visualName={getVisualName(0)}
        visual={getWidget(0, styles.tabularVisual, setTabularCombined, setIsTabularCombinedLoaded)}
        onClick={() => {
          dispatch(trendActions.setRevenueIsDisplayTT(!revenueIsDisplay));
        }}
        toggleFragment={
          <ExportToCSV
            report={tabularCombined}
            isLoaded={isTabularCombinedLoaded}
            fileName={
              isPercentageChange
                ? 'TVT_Percentage'
                : isMovingAverage
                ? 'CMATT_Daily'
                : isCumulative
                ? 'TVT_Cumulative'
                : weeklyViewToggle
                ? 'TVT_Weekly'
                : 'TVT_Daily'
            }
            changeValue={!isPercentageChange}
            weeklyViewToggle={weeklyViewToggle}
            isCumulative={isCumulative}
            otherFilters={trendFilters}
          />
        }
        isWorkbookHotelComparison={activeTab === REPORT_TYPE.TREND_HOTEL}
        comparisonHotel={hotelFocusOn}
        displayHotelName
      />
      <VisualGrid
        isDisplay={revenueIsDisplay}
        visualName={getVisualName(10)}
        visual={getWidget(10, styles.tabularVisual)}
        onClick={() => {
          dispatch(trendActions.setRevenueIsDisplayTT(!revenueIsDisplay));
        }}
        isWorkbookHotelComparison={activeTab === REPORT_TYPE.TREND_HOTEL}
        comparisonHotel={hotelFocusOn}
        displayHotelName
      />
    </>
  );
};
//
export default TrendTimeTabularWidget;
