import './index.scss';
/**
 * Loader common component that uses across modules for indicate loading state
 * @param children - child component which needs to wrap from the loader
 * @param loading - loading state
 */
const Loader = ({ children, loading }) => (
  <>
    <div
      className="opaqueLoaderWrapper"
      style={{
        display: loading ? 'flex' : 'none',
      }}
    >
      <div className="ldsRoller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
    {children}
  </>
);
//
export default Loader;
