import { FormControl, FormLabel, Grid, TextField, Autocomplete } from '@mui/material';
import { CustomStack, CustomToggleSwitch } from 'modules/common/components';
import styles from 'modules/dashboard/components/tab-container/inner-filter/inner-filter.module.scss';
/**
 * Moving Average Window Component
 * @param {Object} props - Component props
 * @param {string} props.movingAverage - Currently selected moving average
 * @param {Array} props.movingAverageList - List of available moving averages
 * @param {function} props.handleMovingAverageChange - Handler for changing the moving average
 * @param {function} props.handleIsMovingAverageChange - Handler for enabling/disabling the moving average
 * @param {boolean} props.isMovingAverage - Boolean to check if moving average is enabled
 */
const MovingAverageWindow = ({
  movingAverage,
  movingAverageList,
  handleMovingAverageChange,
  handleIsMovingAverageChange,
  isMovingAverage,
  isDisabled,
  id = '',
}) => (
  <CustomStack cssClassName={styles.innerFilter}>
    <FormControl>
      <Grid container alignItems="center" mb={2}>
        <FormLabel>Moving Average</FormLabel>
      </Grid>
      <CustomToggleSwitch
        label="Moving Average"
        title=""
        onChange={handleIsMovingAverageChange}
        checked={isMovingAverage}
        disabled={isDisabled}
        id={id}
      />
    </FormControl>
    <FormControl>
      <Autocomplete
        fullWidth
        value={movingAverage}
        options={movingAverageList ?? []}
        getOptionLabel={(option) => (option.label ? `${option.label} days` : '')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterSelectedOptions
        onChange={handleMovingAverageChange}
        disableClearable
        renderInput={(params) => (
          <TextField {...params} label="Moving Average" size="small" color="primary" />
        )}
        sx={{ mt: 2 }}
        disabled={!isMovingAverage}
      />
    </FormControl>
  </CustomStack>
);
//
export default MovingAverageWindow;
