import { CSVLink } from 'react-csv';
import { useState, useEffect, useRef } from 'react';
import { models } from 'powerbi-client';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/common/auth/selectors';
import {
  selectHotelName,
} from 'modules/dashboard/selectors';
import { isEmpty } from 'modules/common/helpers/object';
import { formatDate } from 'modules/dashboard/functions';
import { DATE_PATTERN, TIME_PATTERN } from 'modules/common/constants/date-range';
import { Grid, IconButton, Typography } from '@mui/material';
import { Loader } from 'modules/common/components';
import DownloadIcon from '@mui/icons-material/Download';
import NoDataDialog from 'modules/dashboard/components/tab-container/export-visual-data/components/no-data';
import GenerateCSV from './functions';
/**
 * Export Data component to download visual data in csv format
 * @returns
 */
const ExportToCSV = ({
  report,
  isLoaded,
  fileName,
}) => {
  const csvLink = useRef();
  //
  const currentUser = useSelector(selectAuthUser);
  const selectedHotel = useSelector(selectHotelName);
  //
  const [visualData, setVisualData] = useState([]);
  const [initiateDownload, setInitiateDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isDialog, setIsDialog] = useState(false);
  //
  useEffect(() => {
    if (visualData?.length) {
      setInitiateDownload(true);
    }
  }, [visualData]);
  //
  useEffect(() => {
    if (initiateDownload) {
      csvLink.current.link.click();
      setInitiateDownload(false);
    }
    setLoading(false);
  }, [visualData, initiateDownload]);
  //
  const resetInitData = () => {
    setInitiateDownload(false);
    setLoading(false);
    setIsDialog(true);
  };
  //
  const extractAllMetaFromPBI = async (visualComponents) => {
    let detailsList = []
    detailsList = await Promise.all(visualComponents.map(async component => {
      let data = await component.exportData(models.ExportDataType.Summarized)
      data = data.data.split('\r\n').filter(cmpnt => cmpnt.includes(":"))
      return data[0]
    }))

    return detailsList
  }

  const exportPbiData = async () => {
    setLoading(true);
    if (!isEmpty(report) && isLoaded) {
      //
      try {
        const pages = await report.getPages();
        const currentPage = pages.filter((page) => page.isActive)[0];
        const visuals = await currentPage.getVisuals();
        //
        const visualDetailsComponents = visuals.filter((visual) => visual.type === "multiRowCard")
        const visualMeta = await extractAllMetaFromPBI(visualDetailsComponents).then(metaArray => metaArray)

        let hotelNames = Array.from(document.getElementsByClassName("MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"))
        hotelNames = hotelNames.filter(node => node.parentElement.parentElement.parentElement.className.includes("container_paceVisualGrid"))
        if (hotelNames.length > 0) {
          // decide the index to look by the first letter of the file fileName
          // as TV* refers to Trend and PV* refers to pace
          let domNodeIndex = 0
          if (hotelNames.length === 2) {
            domNodeIndex = fileName[0] === "T" ? 0 : 1
          }
          hotelNames = hotelNames[domNodeIndex].innerText
          if (hotelNames) {
            hotelNames = hotelNames.replaceAll(':', '","').split('\n');
          }
        }
        const visual = visuals.filter((v) => v.type === "pivotTable")[0];
        const result = await visual.exportData(models.ExportDataType.Summarized);
        if (result?.data?.length > 0) {
          result.data = result?.data.replaceAll(" 00:00:00", '');

          let meta = ""
          let csv = GenerateCSV(result, fileName)

          meta += `Username : , ${currentUser?.username}\r\n`;
          meta += `Report Generation Date : ,"${formatDate(
            new Date(),
            DATE_PATTERN
          )}, ${formatDate(new Date(), TIME_PATTERN)}"\r\n`;
          meta += hotelNames.length === 2
            ? `"${hotelNames[0]}"\r\n"${hotelNames[1]}"\r\n`
            : `Hotel , "${selectedHotel?.label}"\r\n`;
          visualMeta.forEach(metaString => {
            metaString.split(',').forEach(metaValue => {
              if (metaValue === '""') return
              meta += `${metaValue.split(':').join(',')}\r\n`
            });
          })
          csv = `${meta} \r\n${csv} `

          setVisualData(csv)
        } else {
          setMessage('No data available to export', resetInitData());
        }
      } catch (error) {
        console.log(error);
        setMessage('Something went wrong', resetInitData());
      }
    } else {
      setMessage('Visual is still loading', resetInitData());
    }
  };
  //
  return (
    <Loader loading={loading}>
      <Grid container direction="row" alignItems="center">
        <Typography
          sx={{
            mr: 1,
          }}
        >
          Export Data
        </Typography>
        <IconButton
          size="small"
          onClick={() => exportPbiData()}
          sx={{
            '& .MuiSvgIcon-root': {
              width: '1.25rem',
              height: '1.25rem',
            },
          }}
        >
          <DownloadIcon />
        </IconButton>
      </Grid>
      <CSVLink
        asyncOnClick
        filename={`${fileName}_${formatDate(new Date(), DATE_PATTERN)}`}
        data={visualData}
        target="_self"
        ref={csvLink}
        sx={{ display: 'none' }}
      />
      <NoDataDialog open={isDialog} onClose={() => setIsDialog(false)} message={message} />
    </Loader>
  );
};
//
export default ExportToCSV;



