import Emitter from 'events';

const emitter = new Emitter();
/**
 * define event emitter functions to add, remove, emit events
 * once - add one time listener function for a event
 * on - add listener function for a event
 * off - remove listener function of a event
 * removeAllListeners - remove all event listeners
 * emit - trigger listener function of a event
 */
const EventEmitter = {
  emitter,
  once: (event, fn) => emitter.once(event, fn),
  subscribe: (event, fn) => emitter.on(event, fn),
  unsubscribe: (event, fn) => emitter.off(event, fn),
  unsubscribeAll: () => emitter.removeAllListeners(),
  dispatch: (event, payload) => {
    emitter.emit(event, payload);
  },
};
// Freeze event emitter object
Object.freeze(EventEmitter);
//
export default EventEmitter;
