import { useDispatch, useSelector } from 'react-redux';
import {
  selectVisualFilters,
  selectHotelName,
  selectPmsSync,
  selectTrendBookingDate,
  selectTrendTargetDate,
  selectEnableHotelGroups,
  selectTrendOtb,
  selectLocalCurrency,
} from 'modules/dashboard/selectors';
import {
  selectIsCumulative,
  selectTrendTimeWeeklyView,
  selectTrendSegmentFocusOn,
  selectTrendHotelNormalizedView,
  selectEnableSecondaryHotelGroups,
  selectTrendSegmentChartBreakdown,
  selectIsSegmentDetailed,
  selectIsForecast,
  selectSegmentGroup1,
  selectSegmentGroup2,
  selectTrendTimeCustomDate,
  selectTrendTimeEndDate,
  selectTrendTimeStartDate,
  selectTrendSymmetricComparisonToggle,
  selectTrendTimeDateComparison,
  selectTabularView,
  selectTrendTimeAlignmentToggle,
  selectActiveTrendTab,
  selectRevenueIsDisplayTT,
  selectOccupiedRoomsIsDisplayTT,
  selectAdrIsDisplayTT,
  selectAvgBookingWindowIsDisplayTT,
  selectAvgLosIsDisplayTT,
  selectCxlIsDisplayTT,
  selectRevenueIsDisplayTS,
  selectOccupiedRoomsIsDisplayTS,
  selectAdrIsDisplayTS,
  selectAvgBookingWindowIsDisplayTS,
  selectCxlIsDisplayTS,
  selectAvgLosIsDisplayTS,
  selectRevenueIsDisplayTH,
  selectOccupiedRoomsIsDisplayTH,
  selectAdrIsDisplayTH,
  selectAvgBookingWindowIsDisplayTH,
  selectAvgLosIsDisplayTH,
  selectCxlIsDisplayTH,
  selectTrendHotelFocusOn,
  selectSymmetricComparisonIsDisplay,
  selectIsPrimary,
  selectIsPercentageChange,
  selectIsTrendDeAggregate,
  selectIsMovingAverage,
  selectMovingAverage,
} from 'modules/dashboard/components/tab-container/trend-tabs/selectors';
import { trendActions } from 'modules/dashboard/components/tab-container/trend-tabs/slice';
import { useEffect } from 'react';
import { generateTrendTimeShareUrl } from '../../inner-filter/functions';

const useGenerateTrendUrl = () => {
  const dispatch = useDispatch();

  const bookingDate = useSelector(selectTrendBookingDate);
  const targetDate = useSelector(selectTrendTargetDate);
  const hotelId = useSelector(selectHotelName);
  const visualFilters = useSelector(selectVisualFilters);
  const pmsSyncToggle = useSelector(selectPmsSync);
  const enableHotelGroups = useSelector(selectEnableHotelGroups);
  const tabularView = useSelector(selectTabularView);
  // time
  const weeklyViewToggle = useSelector(selectTrendTimeWeeklyView);
  const isCumulative = useSelector(selectIsCumulative);
  const isTrendForecast = useSelector(selectIsForecast);
  //
  const vOTB = useSelector(selectTrendOtb);
  //
  const focusOn = useSelector(selectTrendSegmentFocusOn);
  const chartBreakdown = useSelector(selectTrendSegmentChartBreakdown);
  const isDetailed = useSelector(selectIsSegmentDetailed);
  const segmentGroup1 = useSelector(selectSegmentGroup1);
  const segmentGroup2 = useSelector(selectSegmentGroup2);
  //
  const enableSecondaryHotelGroups = useSelector(selectEnableSecondaryHotelGroups);
  const hotelFocusOn = useSelector(selectTrendHotelFocusOn);
  const normalizedViewToggle = useSelector(selectTrendHotelNormalizedView);
  const compareWindowCollapseToggle = useSelector(selectSymmetricComparisonIsDisplay);
  //
  const alignmentToggle = useSelector(selectTrendTimeAlignmentToggle);
  const symmetricComparisonToggle = useSelector(selectTrendSymmetricComparisonToggle);
  const dateComparison = useSelector(selectTrendTimeDateComparison);
  const startDateComparison = useSelector(selectTrendTimeStartDate);
  const endDateComparison = useSelector(selectTrendTimeEndDate);
  const customDate = useSelector(selectTrendTimeCustomDate);
  //
  const activeTab = useSelector(selectActiveTrendTab);
  //
  const revenueIsDisplayTT = useSelector(selectRevenueIsDisplayTT);
  const occupiedRoomsIsDisplayTT = useSelector(selectOccupiedRoomsIsDisplayTT);
  const adrIsDisplayTT = useSelector(selectAdrIsDisplayTT);
  const avgBookingWindowIsDisplayTT = useSelector(selectAvgBookingWindowIsDisplayTT);
  const avgLosIsDisplayTT = useSelector(selectAvgLosIsDisplayTT);
  const cxlIsDisplayTT = useSelector(selectCxlIsDisplayTT);
  //
  const revenueIsDisplayTS = useSelector(selectRevenueIsDisplayTS);
  const occupiedRoomsIsDisplayTS = useSelector(selectOccupiedRoomsIsDisplayTS);
  const adrIsDisplayTS = useSelector(selectAdrIsDisplayTS);
  const avgBookingWindowIsDisplayTS = useSelector(selectAvgBookingWindowIsDisplayTS);
  const avgLosIsDisplayTS = useSelector(selectAvgLosIsDisplayTS);
  const cxlIsDisplayTS = useSelector(selectCxlIsDisplayTS);
  //
  const revenueIsDisplayTH = useSelector(selectRevenueIsDisplayTH);
  const occupiedRoomsIsDisplayTH = useSelector(selectOccupiedRoomsIsDisplayTH);
  const adrIsDisplayTH = useSelector(selectAdrIsDisplayTH);
  const avgBookingWindowIsDisplayTH = useSelector(selectAvgBookingWindowIsDisplayTH);
  const avgLosIsDisplayTH = useSelector(selectAvgLosIsDisplayTH);
  const cxlIsDisplayTH = useSelector(selectCxlIsDisplayTH);
  //
  const isPrimary = useSelector(selectIsPrimary);
  const isPercentageChange = useSelector(selectIsPercentageChange);
  const isTrendDeAggregate = useSelector(selectIsTrendDeAggregate);
  //
  const isMovingAverage = useSelector(selectIsMovingAverage);
  const movingAverage = useSelector(selectMovingAverage);
  const localCurrency = useSelector(selectLocalCurrency);

  // Triggers when filter values dependencies changes
  useEffect(() => {
    if (dateComparison?.length === 0 && !bookingDate && !targetDate && !hotelId) return;
    // Generate trend time filter url
    const trendTimeUrl = generateTrendTimeShareUrl(
      pmsSyncToggle,
      hotelId,
      targetDate,
      vOTB,
      bookingDate,
      tabularView,
      alignmentToggle,
      symmetricComparisonToggle,
      weeklyViewToggle,
      isCumulative,
      startDateComparison,
      endDateComparison,
      customDate,
      dateComparison,
      visualFilters,
      chartBreakdown,
      focusOn,
      hotelFocusOn,
      isDetailed,
      enableHotelGroups,
      hotelId,
      segmentGroup1,
      segmentGroup2,
      normalizedViewToggle,
      enableSecondaryHotelGroups,
      isTrendForecast,
      activeTab,
      revenueIsDisplayTT,
      occupiedRoomsIsDisplayTT,
      adrIsDisplayTT,
      avgBookingWindowIsDisplayTT,
      avgLosIsDisplayTT,
      cxlIsDisplayTT,
      revenueIsDisplayTS,
      occupiedRoomsIsDisplayTS,
      adrIsDisplayTS,
      avgBookingWindowIsDisplayTS,
      avgLosIsDisplayTS,
      cxlIsDisplayTS,
      revenueIsDisplayTH,
      occupiedRoomsIsDisplayTH,
      adrIsDisplayTH,
      avgBookingWindowIsDisplayTH,
      avgLosIsDisplayTH,
      cxlIsDisplayTH,
      compareWindowCollapseToggle,
      isPrimary,
      isPercentageChange,
      isTrendDeAggregate,
      isMovingAverage,
      movingAverage,
      localCurrency
    );
    //
    dispatch(trendActions.setTrendTimeUrl(trendTimeUrl));
  }, [
    pmsSyncToggle,
    bookingDate,
    visualFilters,
    dateComparison,
    alignmentToggle,
    targetDate,
    hotelId,
    symmetricComparisonToggle,
    weeklyViewToggle,
    isCumulative,
    vOTB,
    startDateComparison,
    endDateComparison,
    customDate,
    visualFilters,
    chartBreakdown,
    focusOn,
    isDetailed,
    enableHotelGroups,
    segmentGroup1,
    segmentGroup2,
    normalizedViewToggle,
    hotelFocusOn,
    enableSecondaryHotelGroups,
    isTrendForecast,
    activeTab,
    revenueIsDisplayTT,
    occupiedRoomsIsDisplayTT,
    adrIsDisplayTT,
    avgBookingWindowIsDisplayTT,
    avgLosIsDisplayTT,
    cxlIsDisplayTT,
    revenueIsDisplayTS,
    occupiedRoomsIsDisplayTS,
    adrIsDisplayTS,
    avgBookingWindowIsDisplayTS,
    avgLosIsDisplayTS,
    cxlIsDisplayTS,
    revenueIsDisplayTH,
    occupiedRoomsIsDisplayTH,
    adrIsDisplayTH,
    avgBookingWindowIsDisplayTH,
    avgLosIsDisplayTH,
    cxlIsDisplayTH,
    compareWindowCollapseToggle,
    isPrimary,
    isPercentageChange,
    isTrendDeAggregate,
    isMovingAverage,
    movingAverage,
    localCurrency
  ]);
};
export default useGenerateTrendUrl;
