import { useState } from 'react';
import { models } from 'powerbi-client';
import { isEmpty } from 'modules/common/helpers/object';
import { formatDate } from 'modules/dashboard/functions';
import { DATE_PATTERN, TIME_PATTERN } from 'modules/common/constants/date-range';
import { Grid, IconButton, Typography } from '@mui/material';
import { Loader } from 'modules/common/components';
import DownloadIcon from '@mui/icons-material/Download';
import NoDataDialog from 'modules/dashboard/components/tab-container/export-visual-data/components/no-data';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/common/auth/selectors';
import * as XLSX from "xlsx"
import { selectHotelName } from 'modules/dashboard/selectors';
/**
 * Export Data component to download visual data in format
 * @returns
 */
const ExportToExcel = ({
  report,
  isLoaded,
  fileName,
}) => {
  //
  const currentUser = useSelector(selectAuthUser);
  const selectedHotel = useSelector(selectHotelName);
  //
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isDialog, setIsDialog] = useState(false);
  //
  const resetInitData = () => {
    setLoading(false);
    setIsDialog(true);
  };

  const extractAllMetaFromPBI = async (visualComponents) => {
    let detailsList = []
    detailsList = await Promise.all(visualComponents.map(async component => {
      let data = await component.exportData(models.ExportDataType.Summarized)
      data = data.data.split('\r\n').filter(cmpnt => cmpnt.includes(":"))
      return data[0]
    }))

    return detailsList
  }

  const exportPbiData = async () => {
    setLoading(true);
    if (!isEmpty(report) && isLoaded) {
      //
      try {
        const pages = await report.getPages();
        const currentPage = pages.filter((page) => page.isActive)[0];
        const visuals = await currentPage.getVisuals();
        //
        const visualDetailsComponents = visuals.filter((visual) => visual.type === "multiRowCard")
        let visualMeta = await extractAllMetaFromPBI(visualDetailsComponents).then(metaArray => metaArray)
        //
        const visual = visuals.filter((v) => v.type === "tableEx")[0];
        const result = await visual.exportData(models.ExportDataType.Summarized);
        //
        // Process meta data
        visualMeta = visualMeta.join(',').replaceAll(',', '\r\n').replaceAll(':', ',')
        //
        let workbookData = `Username, ${currentUser?.username}\r\n`
        workbookData += `Report Generation Date, ${formatDate(
          new Date(),
          DATE_PATTERN
        )} ${formatDate(new Date(), TIME_PATTERN)}\r\n`
        workbookData += `Hotel Name, ${selectedHotel?.label}\r\n`
        workbookData += `${visualMeta} \r\n\r\n${result.data}`
        //
        const workbook = XLSX.read(workbookData, { type: "string" });
        XLSX.writeFile(workbook, `${fileName}_${formatDate(new Date(), DATE_PATTERN)}.xlsx`);
        setLoading(false)
      } catch (error) {
        console.log(error);
        setMessage('Something went wrong', resetInitData());
      }
    } else {
      setMessage('Visual is still loading', resetInitData());
    }
  };
  //
  return (
    <Loader loading={loading}>
      <Grid container direction="row" alignItems="center">
        <Typography
          sx={{
            mr: 1,
          }}
        >
          Export Data
        </Typography>
        <IconButton
          size="small"
          onClick={() => exportPbiData()}
          sx={{
            '& .MuiSvgIcon-root': {
              width: '1.25rem',
              height: '1.25rem',
            },
          }}
        >
          <DownloadIcon />
        </IconButton>
      </Grid>
      <NoDataDialog open={isDialog} onClose={() => setIsDialog(false)} message={message} />
    </Loader>
  );
};
//
export default ExportToExcel;
