import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from 'modules/dashboard/constants';
import { initialState } from 'modules/dashboard/components/tab-container/regional-map-tab/slice';
//
const selectDomain = (state) => state[SLICE.REGIONAL_MAP] || initialState;
/**
 * Getting region map filter list state for dashboard component
 */
export const selectRegionMapFilterList = createSelector(
  [selectDomain],
  (state) => state.regionMapFilterList
);
/**
 * Getting region map booking date state for dashboard component
 */
export const selectRegionMapBookingDate = createSelector(
  [selectDomain],
  (state) => state.regionMapBookingDate
);
/**
 * Getting region map chart breakdown state for dashboard component
 */
export const selectRegionMapChartBreakdown = createSelector(
  [selectDomain],
  (state) => state.regionMapChartBreakdown
);
/**
 * Getting region map focus on state for dashboard component
 */
export const selectRegionMapFocusOn = createSelector(
  [selectDomain],
  (state) => state.regionMapFocusOn
);
/**
 * Getting regional map otb state for dashboard component
 */
export const selectRegionalMapOtb = createSelector(
  [selectDomain],
  (state) => state?.regionalMapOtb
);
/**
 * Getting is region detailed in region map tab state for dashboard component
 */
export const selectIsRegionDetailed = createSelector(
  [selectDomain],
  (state) => state?.isRegionDetailed
);
/**
 *  Getting generated regional map tab url
 */
export const selectRegionalMapUrl = createSelector([selectDomain], (state) => state.regionalMapUrl);
/**
 * Getting total occupied rooms table view enable status into the dashboard component
 */
export const selectRegionalBreakdownTabularView = createSelector(
  [selectDomain],
  (state) => state.regionalBreakdownTabularView
);
